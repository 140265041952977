import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import toast, { Toaster } from "react-hot-toast";
import { clearMessage } from "../../store/reducers/globalReducer";
import Wrapper from "./Wrapper"
import { useEditContactUsMutation, useGetContactUsByIdQuery } from "../../store/services/contactusService";
import ScreenHeader from "../../components/ScreenHeader";
import Spinner from "../../components/Spinner";

const ContactUs = () => {
    const { contactusid } = useSelector((state) => state.authReducer);
    const { data, isFetching } = useGetContactUsByIdQuery(contactusid); // Fetch ContactUs data by ID
    const { success } = useSelector(state => state.global);
    const dispatch = useDispatch();
    const [editContactUs, { isLoading }] = useEditContactUsMutation(); // Mutation hook for editing ContactUs
    const [contactDetails, setContactDetails] = useState(data); // State to hold contact details

    useEffect(() => {
        if (success) {
            toast.success(success);
        }
        return () => {
            dispatch(clearMessage());
        }
    }, [success, dispatch]);


    useEffect(() => {
      setContactDetails(data);
    }, [data])
    

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setContactDetails(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await editContactUs({ id: contactusid, ...contactDetails }); // Pass ID and updated details
            if (response.error) {
                toast.error(response.error.message);
            } else {
                toast.success(response.data.msg);
            }
        } catch (error) {
            toast.error('Failed to update contact details.');
            console.error('Failed to update contact details:', error);
        }
    };


    return (
        <Wrapper>
             <Toaster position="top-right" />
            <ScreenHeader>
                <h3>CONTACT Us</h3>
            </ScreenHeader>
            <div className="bg-white text-white px-4 py-6" style={{ borderRadius: '5px' }}>
               {isFetching ? <Spinner />: <form onSubmit={handleSubmit} className="needs-validation" noValidate encType="multipart/form-data">
                    <div className="row g-4">
                        <div className="col-lg-6 position-relative">
                            <label className="form-label">Address</label>
                            <textarea className="form-control" name="address" value={contactDetails?.address} onChange={handleInputChange} required />
                        </div>
                        <div className="col-lg-6 position-relative">
                            <label className="form-label">Working Hours</label>
                            <textarea className="form-control" name="workingHours" value={contactDetails?.workingHours} onChange={handleInputChange} required />
                        </div>
                        <div className="col-lg-3">
                            <label className="form-label">Phone Number</label>
                            <input className="form-control" name="phoneNumber" type="text" value={contactDetails?.phoneNumber} onChange={handleInputChange} required />
                        </div>
                        <div className="col-lg-3">
                            <label className="form-label">Another Phone Number</label>
                            <input className="form-control" name="anotherPhoneNumber" type="text" value={contactDetails?.anotherPhoneNumber} onChange={handleInputChange} />
                        </div>
                        <div className="col-lg-3">
                            <label className="form-label">Email Address</label>
                            <input className="form-control" name="emailAddress" type="email" value={contactDetails?.emailAddress} onChange={handleInputChange} required />
                        </div>
                        <div className="col-lg-3">
                            <label className="form-label">Another Email Address</label>
                            <input className="form-control" name="anotherEmailAddress" type="email" value={contactDetails?.anotherEmailAddress} onChange={handleInputChange} />
                        </div>
                        <div className="col-lg-4">
    <label className="form-label">GST Number</label>
    <input className="form-control" name="gstNumber" type="text" value={contactDetails?.gstNumber} onChange={handleInputChange} />
</div>
<div className="col-lg-4">
    <label className="form-label">Facebook Page Link</label>
    <input className="form-control" name="facebookPageLink" type="text" value={contactDetails?.facebookPageLink} onChange={handleInputChange} />
</div>
<div className="col-lg-4">
    <label className="form-label">Twitter Page Link</label>
    <input className="form-control" name="twitterPageLink" type="text" value={contactDetails?.twitterPageLink} onChange={handleInputChange} />
</div>
<div className="col-lg-4">
    <label className="form-label">Linkedin Page Link</label>
    <input className="form-control" name="linkedinPageLink" type="text" value={contactDetails?.linkedinPageLink} onChange={handleInputChange} />
</div>
<div className="col-lg-4">
    <label className="form-label">Instagram Page Link</label>
    <input className="form-control" name="instagramPageLink" type="text" value={contactDetails?.instagramPageLink} onChange={handleInputChange} />
</div>
<div className="col-lg-4">
    <label className="form-label">Youtube Page Link</label>
    <input className="form-control" name="youtubePageLink" type="text" value={contactDetails?.youtubePageLink} onChange={handleInputChange} />
</div>
<div className="col-lg-4">
    <label className="form-label">Pinterest Page Link</label>
    <input className="form-control" name="pinterestPageLink" type="text" value={contactDetails?.pinterestPageLink} onChange={handleInputChange} />
</div>
<div className="col-lg-4">
    <label className="form-label">Google Plus Page Link</label>
    <input className="form-control" name="googlePlusPageLink" type="text" value={contactDetails?.googlePlusPageLink} onChange={handleInputChange} />
</div>
<div className="col-lg-4">
    <label className="form-label">Whatsapp Number</label>
    <input className="form-control" name="whatsappNumber" type="text" value={contactDetails?.whatsappNumber} onChange={handleInputChange} />
</div>
<div className="col-lg-6 position-relative">
    <label className="form-label">Whatsapp Message</label>
    <textarea className="form-control" name="whatsappMessage" rows="5" value={contactDetails?.whatsappMessage} onChange={handleInputChange} />
</div>
<div className="col-lg-6 position-relative">
    <label className="form-label">Google Map Location Embed Link</label>
    <textarea className="form-control" name="googleMapLocationEmbedLink" rows="5" value={contactDetails?.googleMapLocationEmbedLink} onChange={handleInputChange} />
</div>

                        <div className="col-12">
                            <button  className="w-44 bg-primeColor text-gray-200 h-10 font-titleFont text-base tracking-wide font-semibold hover:bg-black hover:text-white duration-200" type="submit" disabled={isLoading}>Update</button>
                        </div>
                    </div>
                </form>}
            </div>
        </Wrapper>
    );
}

export default ContactUs;
