import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const bannerImageService = createApi({
    reducerPath: 'bannerImage',
    tagTypes: 'bannerImage',
    baseQuery: fetchBaseQuery({
        baseUrl: 'https://mainapi.fine1jewels.com/api/', // Change this to your actual API URL
        prepareHeaders: (headers, { getState }) => {
            const reducers = getState();
            const token = reducers?.authReducer?.adminToken;
            
            headers.set('authorization', token ? `Bearer ${token}` : '');
            return headers;
        }
    }),
    endpoints: (builder) => {
        return {
            createBannerImage: builder.mutation({
                query: ({ url,  image }) => {
                    const formData = new FormData();
                    formData.append('url', url);
                    formData.append('image', image); // Assuming 'image' is the file object
            
                    return {
                        url: 'banner/create',
                        method: 'POST',
                        body: formData,
                       
                    }
                },
                invalidatesTags: ['bannerImage']
            }),
            getAllBannerImages: builder.query({
                query: () => {
                    return {
                        url: '/bannerimages',
                        method: 'GET'
                    }
                },
                providesTags: ['bannerImage']
            }),
            getAllActiveBannerImagesForUser: builder.query({
                query: () => {
                    return {
                        url: '/bannerimages/user',
                        method: 'GET'
                    }
                },
                providesTags: ['bannerImage']
            }),
            getSingleBannerImage: builder.query({
                query: id => {
                    return {
                        url: `/bannerimages/${id}`,
                        method: 'GET'
                    }
                },
                providesTags: ['bannerImage']
            }),
            updateBannerImage: builder.mutation({
                query: ({ id, data }) => {
                    return {
                        url: `/bannerimages/update/${id}`,
                        method: 'PUT',
                        body: data
                    }
                },
                invalidatesTags: ['bannerImage']
            }),
            deleteBannerImage: builder.mutation({
                query: id => {
                    return {
                        url: `/bannerimages/delete/${id}`,
                        method: 'DELETE'
                    }
                },
                invalidatesTags: ['bannerImage']
            })
        }
    }
});

export const {
    useCreateBannerImageMutation,
    useGetAllBannerImagesQuery,
    useGetAllActiveBannerImagesForUserQuery,
    useGetSingleBannerImageQuery,
    useUpdateBannerImageMutation,
    useDeleteBannerImageMutation
} = bannerImageService;

export default bannerImageService;
