import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import currency  from "currency-formatter";
import { useGetCartDetailsQuery } from "../../store/services/homeProducts";
import { removeFromWishlist } from "../../store/reducers/cartReducer";

const Wishlist = () => {
  const { wishlist, wishlistTotal } = useSelector((state) => state.cart);
  const { data, isFetching } = useGetCartDetailsQuery(wishlist);
  const dispatch = useDispatch();
  return (
    <>
      <div id="page" className="hfeed page-wrapper">
        <div id="site-main" className="site-main">
          <div id="main-content" className="main-content">
            <div id="primary" className="content-area">
              <div id="title" className="page-title">
                <div className="section-container">
                  <div className="content-title-heading">
                    <h1 className="text-title-heading">Wishlist</h1>
                  </div>
                  <div className="breadcrumbs">
                    <Link to="/">Home</Link>
                    <span className="delimiter" />
                    <Link to="/Shop">Shop</Link>
                    <span className="delimiter" />
                    Shopping Cart
                  </div>
                </div>
              </div>
              <div id="content" className="site-content" role="main">
                <div className="section-padding">
                  <div className="section-container p-l-r">
                    <div className="shop-wishlist">
                    {wishlist.length ?   <table className="wishlist-items">
                        <tbody>
                          

                                                
                        {data?.products?.map((item, index) => {
return(  
  
                          <tr className="wishlist-item">
                            <td className="wishlist-item-remove">
                              <span  onClick={() => {
                              dispatch(removeFromWishlist(item._id));
                            }}/>
                            </td>
                            <td className="wishlist-item-image">
                              <Link to={`/product/${item._id}`}>
                                <img
                                  width={600}
                                  height={600}
                                  src={`https://fine1jewels.com/images/${item.image}`}
                                  alt=""
                                />
                              </Link>
                            </td>
                            <td className="wishlist-item-info">
                              <div className="wishlist-item-name">
                                <Link to={`/product/${item._id}`}>{currency.format(item.unitPrice, { code: "INR" })}</Link>
                              </div>
                              <div className="wishlist-item-price">
                                <span>{item?.title}</span>
                              </div>
                              <div className="wishlist-item-time">
                                {wishlist[index].karat} Karats
                              </div>
                            </td>
                            <td className="wishlist-item-actions">
                              <div className="wishlist-item-stock">
                                In stock
                              </div>
                              <div className="wishlist-item-add">
                                <div
                                  className="btn-add-to-cart"
                                  data-title="Add to cart"
                                >
                                  <Link
                                    rel="nofollow"
                                    to={`/product/${item._id}`}
                                    className="product-btn"
                                  >
                                    View More
                                  </Link>
                                </div>
                              </div>
                            </td>
                          </tr>
)})}
                        
                        </tbody>
                      </table> :
                    <div className="shop-cart-empty">
                      <div className="notices-wrapper">
                        <p className="cart-empty">
                          Your Wishlist is currently empty.
                        </p>
                      </div>
                      <div className="return-to-shop">
                        <Link className="button" to="/">
                          Return to shop
                        </Link>
                      </div>
                    </div>}

                    </div>
                  </div>
                </div>
              </div>
              {/* #content */}
            </div>
            {/* #primary */}
          </div>
          {/* #main-content */}
        </div>
      </div>
      {/* Back Top button */}
      <div className="back-top button-show">
        <i className="arrow_carrot-up" />
      </div>
      {/* Search */}
      
      {/* Page Loader */}
      {/* <div className="page-preloader">
        <div className="loader">
          <div />
          <div />
        </div>
      </div> */}
      {/* Dependency Scripts */}
      {/* Site Scripts */}
    </>
  );
};

export default Wishlist;
