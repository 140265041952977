import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import toast, { Toaster } from "react-hot-toast";
import Wrapper from "./Wrapper"
import { useUpdateLogoMutation } from "../../store/services/authService";

const Logo = () => {
    const [file, setFile] = useState(null);
    const [updateLogo, { isLoading }] = useUpdateLogoMutation();
  
    const handleLogoUpload = (e) => {
      setFile(e.target.files[0]);
    };
  
    const handleLogoUpdate = async (filename) => {
      if (!file) {
        alert("Please select a file.");
        return;
      }
  
      try {
        const formData = new FormData();
        formData.append("filename", filename);
        formData.append("logo", file);
  
        await updateLogo(formData);
  
        alert("Logo updated successfully");
      } catch (error) {
        console.error("Error updating logo:", error);
        alert("Failed to update logo.");
      }
    };
  
    return (
      <Wrapper>
        <Toaster position="top-right" />
  
        <div style={{ borderRadius: "5px" }}>
          <div>
            <table className="w-full bg-gray-900 rounded-md">
              <thead>
                <tr className="border-b border-gray-800 text-left">
                  <th className="p-3 uppercase text-sm font-medium text-gray-500">
                    S. No.
                  </th>
                  <th className="p-3 uppercase text-sm font-medium text-gray-500">
                    Logo
                  </th>
                  <th className="p-3 uppercase text-sm font-medium text-gray-500">
                    Edit
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className="odd:bg-gray-800" key={1}>
                  <td className="p-3">1</td>
                  <td className="p-3">
                    <img
                      src={`/images/logo/1.png`}
                      className="w-20 h-20 rounded-md object-cover"
                      alt="Logo 1"
                    />
                  </td>
                  <td className="my-8 capitalize text-sm font-normal text-gray-400 flex flex-row col-8">
                    <input
                      type="file"
                      accept="image/*"
                      name="image1"
                      id="image1"
                      className="input-file"
                      onChange={handleLogoUpload}
                    />
                    <button
                      className="btn btn-warning"
                      onClick={() => handleLogoUpdate("1.png")}
                      disabled={isLoading}
                    >
                      {isLoading ? "Updating..." : "Update"}
                    </button>
                  </td>
                </tr>
                <tr className="odd:bg-gray-800" key={2}>
                  <td className="p-3">2</td>
                  <td className="p-3">
                    <img
                      src={`/images/logo/2.png`}
                      className="w-20 h-20 rounded-md object-cover"
                      alt="Logo 2"
                    />
                  </td>
                  <td className="my-8 capitalize text-sm font-normal text-gray-400 flex flex-row col-8">
                    <input
                      type="file"
                      accept="image/*"
                      name="image2"
                      id="image2"
                      className="input-file"
                      onChange={handleLogoUpload}
                    />
                    <button
                      className="btn btn-warning"
                      onClick={() => handleLogoUpdate("2.png")}
                      disabled={isLoading}
                    >
                      {isLoading ? "Updating..." : "Update"}
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </Wrapper>
    );
  };
  
  export default Logo;