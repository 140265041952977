import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import toast, { Toaster } from "react-hot-toast";
import { clearMessage } from "../../store/reducers/globalReducer";
import Wrapper from "./Wrapper";
import {
  useGetProductsQuery,
  useDeleteProductMutation,
  useUpdateStatusMutation,
} from "../../store/services/productService";
import ScreenHeader from "../../components/ScreenHeader";
import Spinner from "../../components/Spinner";
import Pagination from "../../components/Pagination";
import currency from "currency-formatter";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import {
  useDeleteInformationMutation,
  useGetInformationQuery,
  useUpdateInformationStatusMutation,
} from "../../store/services/informationService";
import HTMLReactParser from "html-react-parser";
import html2plaintext from "html2plaintext";
import { FaEdit } from "react-icons/fa";
import { ImCross } from "react-icons/im";
import { FaCheck } from "react-icons/fa";

const PolicyPages = () => {
  const [show, setShow] = useState(false);
  const [selected_item, setselected_item] = useState(null);

  const handleShow = (item) => {
    setselected_item(item);
    setShow(true);
  };
  const handleClose = () => setShow(false);
  let { page } = useParams();
  if (!page) {
    page = 1;
  }
  const { data = [], isFetching } = useGetInformationQuery(page);
  console.log(data);
  const { success } = useSelector((state) => state.global);
  const dispatch = useDispatch();
  useEffect(() => {
    if (success) {
      toast.success(success);
    }
    return () => {
      dispatch(clearMessage());
    };
  }, []);
  const [delProduct, response] = useDeleteInformationMutation();
  const [updateProduct, { response: updateresp }] =
    useUpdateInformationStatusMutation();

  const deleteProduct = (id) => {
    if (window.confirm("Are you really want to delete this product?")) {
      delProduct(id);
    }
  };

  const activeProduct = (id, status) => {
    if (
      window.confirm(
        `Are you really want to ${
          status ? "inactive" : "activate"
        } this product?`
      )
    ) {
      updateProduct({ id: id, status: status ? 0 : 1 });
    }
  };

  console.log(selected_item,"selected")

  return (
    <Wrapper>
      <ScreenHeader>
        <Link to="/dashboard/createpolicypages" className="btn-dark">
          create Page
        </Link>
        <Toaster position="top-right" />
      </ScreenHeader>
      {!isFetching ? (
        data?.length > 0 ? (
          <div>
            <table className="w-full bg-gray-900 rounded-md">
              <thead>
                <tr className="border-b border-gray-800 text-left">
                  <th className="p-3 uppercase text-sm font-medium text-gray-500">
                    s no.
                  </th>
                  <th className="p-3 uppercase text-sm font-medium text-gray-500">
                    title
                  </th>
                  <th className="p-3 uppercase text-sm font-medium text-gray-500">
                    Details
                  </th>
                  <th className="p-3 uppercase text-sm font-medium text-gray-500">
                    Actions
                  </th>
                  <th className="p-3 uppercase text-sm font-medium text-gray-500">
                    delete
                  </th>
                </tr>
              </thead>
              <tbody>
                {data?.map((product, index) => (
                  <tr className="odd:bg-gray-800" key={product._id}>
                    <td className="p-3 capitalize text-sm font-normal text-gray-400">
                      {" "}
                      {index + 1}{" "}
                    </td>
                    <td className="p-3 capitalize text-sm font-normal text-gray-400">
                      {product.title}
                    </td>

                    <td className="p-3 capitalize text-sm font-normal text-gray-400">
                      <Button
                        onClick={() => handleShow(product)}
                        className="btn btn-warning"
                      >
                        DETAILS
                      </Button>
                    </td>
                    {/* <td className="p-3 capitalize text-sm font-normal text-gray-400"><Link to={`/dashboard/edit-product/${product._id}`} className="btn btn-warning">edit</Link></td> */}
                    <td className="p-3 capitalize text-sm font-normal text-gray-400">
                    <Link to={`/dashboard/policypages/${product.url}`} className="btn btn-warning"><FaEdit />
</Link>
                      <span
                        className={`btn ${
                          product.status ? "btn-success" : "btn-danger"
                        } cursor-pointer ml-3`}
                        onClick={() =>
                          activeProduct(product._id, product.status)
                        }
                      >
                        {product.status ? <FaCheck/>:<ImCross/>  }
                      </span>
                    </td>
                    <td className="p-3 capitalize text-sm font-normal text-gray-400">
                      <span
                        className="btn btn-danger cursor-pointer"
                        onClick={() => deleteProduct(product._id)}
                      >
                        delete
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <Pagination
              page={parseInt(page)}
              perPage={data.perPage}
              count={data.count}
              path="dashboard/products"
            />
          </div>
        ) : (
          "No Pages!"
        )
      ) : (
        <Spinner />
      )}

      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{selected_item?.title}</Modal.Title>
        </Modal.Header>
      {selected_item?.details &&  <Modal.Body>{HTMLReactParser(selected_item?.details)}</Modal.Body>}
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Wrapper>
  );
};
export default PolicyPages;
