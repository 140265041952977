import {useEffect} from "react"
import { Link, useParams } from "react-router-dom";
import {useDispatch, useSelector} from "react-redux"
import toast, { Toaster } from 'react-hot-toast';
import { clearMessage } from "../../store/reducers/globalReducer";
import Wrapper from "./Wrapper"
import { useGetProductsQuery, useDeleteProductMutation } from "../../store/services/productService";
import ScreenHeader from "../../components/ScreenHeader";
import Spinner from "../../components/Spinner";
import Pagination from "../../components/Pagination";
import currency  from "currency-formatter";
import { useDeleteContactMutation, useGetAllContactsQuery } from "../../store/services/contactService";
import { useDeleteSubscriptionMutation, useGetAllSubscriptionsQuery } from "../../store/services/newsletterSubscriptionService";
const Subscribers = () => {
   let {page} = useParams();
   if(!page) {
      page = 1;
   }
   const {data = [], isFetching} = useGetAllSubscriptionsQuery();
   console.log(data);
   const {success} = useSelector(state => state.global);
    const dispatch = useDispatch();
    useEffect(() => {
     if(success) {
       toast.success(success);
     }
     return () => {
        dispatch(clearMessage())
     }
    }, [])
    const [delProduct, response] = useDeleteSubscriptionMutation();
    
    const deleteProduct = id => {
      if(window.confirm("Are you really want to delete this message?")) {
          delProduct(id);
          window.location.reload();
      }
    }
    return(
       <Wrapper>
         <ScreenHeader>
         
         <h3>CONTACT ENQUIRY</h3>
          </ScreenHeader>
          {!isFetching ? data?.length > 0 ? <div>
            <table className="w-full bg-gray-900 rounded-md">
            <thead>
                    <tr className="dashboard-tr">
                       <th className="dashboard-th">S No.</th>
                       <th className="dashboard-th">mail</th>
                       <th className="dashboard-th">Date/Time</th>
                       <th className="dashboard-th">delete</th>
                    </tr>
                 </thead>
                 <tbody>
                  {data?.map((item,index) => (
                     <tr className="odd:bg-gray-800" key={item?._id}>
                        <td className="dashboard-td">{index + 1}</td>
                        <td className="dashboard-td">{item?.email}</td>
                        <td className="dashboard-td">     {item.subscribedAt.split('T')[0]} at {item.subscribedAt.split('T')[1].split('.')[0]}</td>
                        <td className="dashboard-td " style={{ borderRight: "1px dashed white" }}><span className="btn btn-danger cursor-pointer" onClick={() => deleteProduct(item._id)}>delete</span></td>
                     </tr>
                  ))}
                 </tbody>
            </table>
            <Pagination page={parseInt(page)} perPage={data.perPage} count={data.count} path="dashboard/products" />
          </div> : 'No Subscribers!' : <Spinner />}
       </Wrapper>
    )
}
export default Subscribers;