import React, { useState } from 'react';


const NewsletterModal = ({ isOpen, onClose }) => {
  const [email, setEmail] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [message, setMessage] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    if (email) {
      setSubmitted(true);
      setMessage(`Your coupon has been sent to your email: ${email}`);
      setTimeout(() => {
        setSubmitted(false);
        setEmail('');
        onClose();
      }, 5000); // Close after 5 seconds
    }
  };

  if (!isOpen) return null;

  return (
    <div className="popup-shadow">
      <div className="newsletter-popup">
        <a href="#" className="newsletter-close" onClick={onClose}>
          &times;
        </a>
        <div className="newsletter-container">
          <div className="newsletter-img">
            <img src="media/banner/newsletter-popup.jpg" alt="" />
          </div>
          <div className="newsletter-form">
            <form onSubmit={handleSubmit}>
              {!submitted ? (
                <>
                  <div className="newsletter-title">
                    <div className="title">
                      Get <br /> 5% off
                    </div>
                    <div className="sub-title">
                      on your first order. Offer ends soon.
                    </div>
                  </div>
                  <div className="newsletter-input clearfix">
                    <input
                      type="email"
                      name="your-email"
                      size={40}
                      className="form-control"
                      placeholder="Enter Your Email ..."
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                    <input
                      type="submit"
                      value="Subscribe"
                      className="form-control"
                    />
                  </div>
                  <div className="newsletter-no" onClick={onClose}>
                    No thanks!
                  </div>
                </>
              ) : (
                <div className="newsletter-message" style={{ color: 'green' }}>
                  {message}
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewsletterModal;
