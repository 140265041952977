import React, { useState } from "react";
import { FaPlus, FaMinus, FaPhone, FaQuestionCircle, FaArrowLeft } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import { BsCalendar3 } from "react-icons/bs";
import { MdOutlineShoppingBag } from "react-icons/md";
import { FaCreditCard, FaPaypal, FaClock } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useGetContactUsByIdQuery } from "../../store/services/contactusService";

const TryAtHome = () => {
  const [selectedDesigns, setSelectedDesigns] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [expandedQuestion, setExpandedQuestion] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const navigate = useNavigate();
  
  const { contactusid } = useSelector((state) => state.authReducer);
	const { data:contactusdata, isFetching:contactusfetch } = useGetContactUsByIdQuery(contactusid);

  const fakeDesigns = [
    {
      id: 1,
      name: "Diamond Necklace",
      image:
        "https://images.unsplash.com/photo-1599643478518-a784e5dc4c8f?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1000&q=80",
      price: "$1,999",
    },
    {
      id: 2,
      name: "Gold Bracelet",
      image:
        "https://images.unsplash.com/photo-1573408301185-9146fe634ad0?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1000&q=80",
      price: "$799",
    },
    {
      id: 3,
      name: "Pearl Earrings",
      image:
        "https://images.unsplash.com/photo-1535632066927-ab7c9ab60908?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1000&q=80",
      price: "$499",
    },
    {
      id: 4,
      name: "Ruby Ring",
      image:
        "https://images.unsplash.com/photo-1605100804763-247f67b3557e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1000&q=80",
      price: "$1,299",
    },
  ];

  const handleAddDesign = () => {
    setShowModal(true);
  };

  const handleSelectDesign = (design) => {
    if (selectedDesigns.length < 4) {
      setSelectedDesigns([...selectedDesigns, design]);
    }
    setShowModal(false);
  };

  const handleRemoveDesign = (id) => {
    setSelectedDesigns(selectedDesigns.filter((design) => design.id !== id));
  };

  const toggleQuestion = (index) => {
    setExpandedQuestion(expandedQuestion === index ? null : index);
  };

  const faqQuestions = [
    {
      question: "What is Try at Home?",
      answer:
        "Try at Home is a service that allows you to try our designs in the comfort of your home. It's free, with no obligation to buy. Simply pick your favorite designs and schedule an appointment at your convenience.",
    },
    { question: "Is Try at Home available outside India?", answer: "Currently, our Try at Home service is only available within India." },
    {
      question: "Which cities offer this service?",
      answer: "Our Try at Home service is available in major cities across India. Please check our website or contact customer service for the most up-to-date list of cities.",
    },
    { question: "Is the Try at Home service free?", answer: "Yes, our Try at Home service is completely free of charge. There's no obligation to purchase." },
    { question: "Which products can I try?", answer: "You can try a wide range of our jewelry products including necklaces, earrings, rings, and bracelets. Some high-value or limited edition pieces may not be available for this service." },
    { question: "How many products can I try?", answer: "You can select up to 4 designs to try at home during a single appointment." },
    { question: "How will you send me the products for trial?", answer: "Our trained jewelry consultant will bring the selected designs to your specified location at the scheduled time." },
    { question: "Can I request a Try at Home at my office?", answer: "Yes, you can schedule a Try at Home appointment at your office or any other location convenient for you." },
    {
      question: "What if my preferred date or time slot isn't available?",
      answer: "If you don't find a suitable slot, please contact our customer service. We'll do our best to accommodate your preferred date and time.",
    },
    {
      question: "Can I place an order immediately after trying the products?",
      answer: "Yes, you can place an order immediately with our consultant if you decide to purchase any of the tried designs.",
    },
    {
      question: "Are all the jewelry pieces real gold?",
      answer: "Yes, all jewelry pieces brought for the Try at Home appointment are authentic and made of real gold and precious stones as described in their specifications.",
    },
    {
      question: "What health and safety measures are in place for consultants?",
      answer: "We follow strict health and safety protocols. Our consultants undergo regular health check-ups and adhere to all necessary hygiene practices.",
    },
    {
      question: "What safety measures are taken during the appointment?",
      answer: "Our consultants follow all necessary safety measures including wearing masks, using hand sanitizers, and maintaining social distance. All jewelry pieces are thoroughly sanitized before and after each appointment.",
    },
  ];

  const generateDateOptions = () => {
    const options = [];
    const today = new Date();
    for (let i = 0; i < 7; i++) {
      const date = new Date(today);
      date.setDate(today.getDate() + i);
      options.push(date);
    }
    return options;
  };

  const dateOptions = generateDateOptions();

  const handleDateSelect = (date) => {
    setSelectedDate(date);
  };

  const formatDate = (date) => {
    const days = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
    return `${days[date.getDay()]}\n${date.getDate()}`;
  };

  const timeOptions = ['12:00 PM', '01:30 PM', '03:00 PM', '04:30 PM', '06:00 PM'];

  const handleTimeSelect = (time) => {
    setSelectedTime(time);
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <div className="max-w-auto mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <button
        onClick={handleGoBack}
        className="flex items-center text-yellow-500 hover:text-yellow-600 transition duration-300 mb-4"
      >
        <FaArrowLeft className="mr-2" />
        Back
      </button>
      <div className="flex flex-col lg:flex-row">
        <div className="lg:w-2/3 lg:pr-8 border-b lg:border-b-0 lg:border-r border-gray-300 pb-8 lg:pb-0">
          <h1 className="text-3xl font-semibold mb-6 text-gray-800">
            Try at Home Service
          </h1>
          <p className="text-gray-600 mb-8 text-base">
            Our Try at Home consultant will visit your location to showcase the
            designs, at a time and place convenient for you.
          </p>

          <div className="mb-10">
            <h2 className="text-xl font-semibold mb-4 text-gray-800 flex items-center">
              <BsCalendar3 className="mr-2 text-yellow-500" />
              Select a Date
            </h2>
            <div className="flex space-x-2 overflow-x-auto pb-2">
              {dateOptions.map((date, index) => (
                <button
                  key={index}
                  onClick={() => handleDateSelect(date)}
                  className={`flex flex-col items-center justify-center p-3 rounded-md transition-all duration-300 min-w-[70px] ${
                    selectedDate && selectedDate.getTime() === date.getTime()
                      ? "bg-yellow-500 text-white"
                      : "bg-gray-100 text-gray-800 hover:bg-gray-200"
                  }`}
                >
                  {formatDate(date).split('\n').map((line, i) => (
                    <span key={i} className={i === 0 ? "text-xs" : "text-lg font-semibold"}>{line}</span>
                  ))}
                </button>
              ))}
            </div>
          </div>

          <div className="mb-10">
            <h2 className="text-xl font-semibold mb-4 text-gray-800 flex items-center">
              <FaClock className="mr-2 text-yellow-500" />
              Select a Time
            </h2>
            <div className="flex flex-wrap gap-2">
              {timeOptions.map((time, index) => (
                <button
                  key={index}
                  onClick={() => handleTimeSelect(time)}
                  className={`p-3 rounded-md transition-all duration-300 ${
                    selectedTime === time
                      ? "bg-yellow-500 text-white"
                      : "bg-gray-100 text-gray-800 hover:bg-gray-200"
                  }`}
                >
                  {time}
                </button>
              ))}
            </div>
          </div>

          <div className="mb-10">
            <h2 className="text-xl font-semibold mb-4 text-gray-800 flex items-center">
              <MdOutlineShoppingBag className="mr-2 text-yellow-500" />
              Your Shortlisted Designs
            </h2>
            <p className="text-sm text-yellow-600 mb-4 font-medium">
              {selectedDesigns.length}/4 AVAILABLE
            </p>
            <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4">
              {selectedDesigns.map((design) => (
                <div
                  key={design.id}
                  className="bg-white border border-gray-200 rounded-lg shadow-sm overflow-hidden transition-all duration-300 hover:shadow-md"
                >
                  <div className="relative aspect-square">
                    <img
                      src={design.image}
                      alt={design.name}
                      className="w-full h-full object-cover"
                    />
                    <div className="absolute inset-0 bg-black bg-opacity-50 flex flex-col items-center justify-center opacity-0 hover:opacity-100 transition-opacity duration-300">
                      <p className="text-white text-sm font-medium mb-2">
                        {design.name}
                      </p>
                      <p className="text-white text-xs mb-3">{design.price}</p>
                      <button
                        onClick={() => handleRemoveDesign(design.id)}
                        className="bg-red-500 text-white text-xs px-3 py-1 rounded-full hover:bg-red-600 transition duration-300 flex items-center"
                      >
                        <IoMdClose className="mr-1" /> Remove
                      </button>
                    </div>
                  </div>
                </div>
              ))}
              {Array.from({ length: 4 - selectedDesigns.length }).map(
                (_, index) => (
                  <div
                    key={index}
                    onClick={handleAddDesign}
                    className="bg-gray-50 rounded-lg flex flex-col items-center justify-center aspect-square cursor-pointer hover:bg-gray-100 transition duration-300 border-2 border-dashed border-gray-300"
                  >
                    <FaPlus className="text-3xl text-gray-400 mb-2" />
                    <span className="text-sm text-gray-500 font-medium">Add Design</span>
                  </div>
                )
              )}
            </div>
          </div>

          <button className="w-full bg-yellow-500 text-white py-3 rounded-md hover:bg-yellow-600 transition duration-300 font-medium text-lg shadow-md">
            PROCEED TO CONFIRM
          </button>
        </div>

        <div className="lg:w-1/3 mt-8 lg:mt-0 lg:pl-8">
          <div className="bg-yellow-50 p-6 rounded-lg mb-8 shadow-sm">
            <div className="flex items-center mb-4">
              <FaPhone className="text-xl text-yellow-600 mr-3" />
              <span className="text-lg font-medium text-gray-800">
                Need help with designs or appointment?
              </span>
            </div>
            <p className="text-gray-600">
              Call us if you have any questions or need clarification
            </p>
            <p className="text-yellow-600 font-medium mt-2">{contactusdata?.whatsappNumber}</p>
          </div>

          <div className="text-sm text-yellow-600 mb-6 cursor-pointer hover:underline font-medium">
            My date & time is not listed?
          </div>

          <div className="mb-8">
            <h2 className="text-xl font-semibold mb-4 text-gray-800 flex items-center">
              <FaQuestionCircle className="mr-2 text-yellow-500" />
              Frequently Asked Questions
            </h2>
            <p className="text-sm text-gray-600 mb-6">
              Reach us {contactusdata?.workingHours} on {contactusdata?.whatsappNumber}
              (Helpline) or write to us for any queries at 
              {" "}{contactusdata?.emailAddress},{contactusdata?.anotherEmailAddress}   
            </p>
            <div className="space-y-3">
              {faqQuestions.map((faq, index) => (
                <div
                  key={index}
                  className="border border-gray-200 rounded-lg overflow-hidden bg-white shadow-sm"
                >
                  <div
                    className="flex justify-between items-center p-4 cursor-pointer hover:bg-gray-50 transition-colors duration-300"
                    onClick={() => toggleQuestion(index)}
                  >
                    <span className="font-medium text-gray-800">
                      {faq.question}
                    </span>
                    <span className="text-xl text-yellow-500">
                      {expandedQuestion === index ? <FaMinus /> : <FaPlus />}
                    </span>
                  </div>
                  {expandedQuestion === index && (
                    <div className="p-4 bg-gray-50 border-t border-gray-200">
                      <p className="text-sm text-gray-600">{faq.answer}</p>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <footer className="mt-12 text-sm text-gray-500 border-t border-gray-300 pt-8">
        <p>Contact Us: +91-44-66075200 (Helpline) | contactus@caratlane.com</p>
        <div className="flex flex-col sm:flex-row justify-between items-center mt-4">
          <p className="mb-4 sm:mb-0">© 2023 CaratLane</p>
          <div className="flex space-x-4">
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/5/5e/Visa_Inc._logo.svg"
              alt="Visa"
              className="h-6"
            />
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/2/2a/Mastercard-logo.svg"
              alt="Mastercard"
              className="h-6"
            />
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/b/b5/PayPal.svg"
              alt="PayPal"
              className="h-6"
            />
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/f/fa/American_Express_logo_%282018%29.svg"
              alt="American Express"
              className="h-6"
            />
          </div>
        </div>
      </footer>

      {showModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
          <div className="bg-white rounded-lg p-6 w-full max-w-md">
            <h3 className="text-lg font-semibold mb-4 text-gray-800">
              Select a Design
            </h3>
            <div className="grid grid-cols-2 gap-4">
              {fakeDesigns.map((design) => (
                <div
                  key={design.id}
                  onClick={() => handleSelectDesign(design)}
                  className="bg-gray-100 p-4 rounded-md flex flex-col items-center justify-center cursor-pointer hover:bg-gray-200 transition duration-300"
                >
                  <img
                    src={design.image}
                    alt={design.name}
                    className="w-full h-32 object-cover rounded-md mb-2"
                  />
                  <p className="text-sm font-medium text-gray-800">
                    {design.name}
                  </p>
                  <p className="text-xs text-gray-600">{design.price}</p>
                </div>
              ))}
            </div>
            <button
              onClick={() => setShowModal(false)}
              className="mt-4 w-full bg-gray-200 text-gray-800 py-2 rounded-md hover:bg-gray-300 transition duration-300"
            >
              Cancel
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default TryAtHome;
