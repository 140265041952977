import { useParams, Link } from "react-router-dom";
import ScreenHeader from "../../components/ScreenHeader";
import Wrapper from "./Wrapper";
import { useGetOrdersQuery } from "../../store/services/orderService";
import Spinner from "../../components/Spinner";
import Pagination from "../../components/Pagination";
import currency from "currency-formatter";
import { discount } from "../../utils/discount";
const Orders = () => {
  let { page } = useParams();
  page = page ? page : 1;
  const { data, isFetching } = useGetOrdersQuery();
  console.log(data);
  return (
    <Wrapper>
      <ScreenHeader>Orders</ScreenHeader>
      {!isFetching ? (
        data?.orders?.length > 0 && (
          <>
            <div className="overflow-x-auto">
              <table className="dashboard-table">
                <thead>
                  <tr className="dashboard-tr">
                    <th className="dashboard-th">S. No.</th>
                    <th className="dashboard-th">Order ID</th>
                    <th className="dashboard-th">Image</th>
                    <th className="dashboard-th">Product</th>
                    <th className="dashboard-th">Customer</th>
                    <th className="dashboard-th">quantities</th>
                    <th className="dashboard-th">Amount</th>
                    <th className="dashboard-th">Payment Method</th>
                    <th className="dashboard-th">Date &amp; Time</th>
                    <th className="dashboard-th">received</th>
                    <th className="dashboard-th">Delivered</th>
                    <th className="dashboard-th">details</th>
                  </tr>
                </thead>
                <tbody>
                  { data?.orders?.map((order,index) => (
                    <tr className="odd:bg-gray-800" key={order?._id}>
                      <td className="dashboard-td">{index + 1}</td>
                      <td className="dashboard-td"><Link to={`/dashboard/order-summary/${order?._id}`}>#{order?.productId?._id}</Link></td>
                      <td className="dashboard-td">
                        <img
                          src={`/images/${order?.productId?.images[0]}`}
                          alt="image name"
                          className="w-[35px] h-[35px] md:w-[50px] md:h-[50px] rounded-full object-cover"
                        />
                      </td>
                      <td className="dashboard-td">{order.productId?.title}</td>
                      <td className="dashboard-td">{order?.userId?.name}</td>
                      <td className="dashboard-td">{order?.quantities}</td>
                      <td className="dashboard-td">{currency.format(
                          discount(
                            order.productId?.price,
                            order.productId?.discount
                          ),
                          { code: "INR" }
                        )}</td>
                      <td className="dashboard-td">
                        {order?.paymenttype == "COD" ? "Cash On Delivery" : "Online"}
                      </td>
                      <td className="dashboard-td">
                        {order?.updatedAt.split('T')[0]}<br/>{order?.updatedAt.split('T')[1].split('.')[0]}
                      </td>
                    
                      <td className="dashboard-td">
                        {order?.received ? "Yes" : "No"}
                      </td>
                      <td className="dashboard-td">
                        {order?.status ? "Yes" : "No"}
                      </td>
                  
                      <td className="dashboard-td">
                        <Link
                          to={`/dashboard/order-details/${order?._id}`}
                          className="btn btn-warning bg-indigo-600 text-xs font-bold"
                        >
                          details
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <Pagination
              page={parseInt(page)}
              perPage={data.perPage}
              count={data.count}
              path="dashboard/orders"
            />
          </>
        )
      ) : (
        <Spinner />
      )}
    </Wrapper>
  );
};

export default Orders;
