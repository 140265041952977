import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import toast, { Toaster } from "react-hot-toast";
import ScreenHeader from "../../components/ScreenHeader";
import Wrapper from "./Wrapper";
import ImagesPreview from "../../components/ImagesPreview";
import { setSuccess } from "../../store/reducers/globalReducer";
import { useCreateBrandMutation } from "../../store/services/brandService";

const CreateBrand = () => {
    const [state, setState] = useState({
        name: "",
        image: null,
        imagePosition: "center" // New state for image position
    });

    const [preview, setPreview] = useState("");

    const handleInput = (e) => {
        setState({ ...state, [e.target.name]: e.target.value });
    };

    const handleImageUpload = (e) => {
        const file = e.target.files[0];
        console.log("Selected file:", file);
        console.log("MIME type:", file.type);
        const extension = file.name.split('.').pop().toLowerCase();
        console.log("File extension:", extension);
        setState({ ...state, image: file });
        
        const reader = new FileReader();
        reader.onloadend = () => {
            setPreview(reader.result);
        };
        reader.readAsDataURL(e.target.files[0]);
    };

    const [createNewBrand, response] = useCreateBrandMutation();

    const createBrand = async (e) => {
        e.preventDefault();
        try {
            console.log("State:", state);
            const formData = new FormData();
            formData.append("name", state.name);
            formData.append("image", state.image);
            formData.append("slug", state.imagePosition); // Append the image position
            console.log("FormData:", formData); // Log the FormData object
        
            await createNewBrand(formData).unwrap();
        } catch (error) {
            console.error("Create brand error:", error);
        }
    };

    useEffect(() => {
        if (!response.isSuccess) {
            response?.error?.data?.errors.map((err) => {
                toast.error(err.msg);
            });
        }
    }, [response]);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (response?.isSuccess) {
            dispatch(setSuccess(response?.data?.msg));
            navigate("/dashboard/brands");
        }
    }, [response]);

    return (
        <Wrapper>
            <ScreenHeader>
                <Link to="/dashboard/brands" className="btn-dark">
                    <i className="bi bi-arrow-left-short"></i> Home Category List
                </Link>
            </ScreenHeader>
            <Toaster position="top-right" reverseOrder={true} />
            <div className="flex flex-wrap -mx-3">
                <form className="w-full xl:w-8/12 p-3" onSubmit={createBrand}>
                    <div className="flex flex-wrap">
                        <div className="w-full md:w-6/12 p-3">
                            <label htmlFor="name" className="label">
                                Name
                            </label>
                            <input
                                type="text"
                                name="name"
                                className="form-control"
                                id="name"
                                placeholder="Name..."
                                onChange={handleInput}
                                value={state.name}
                            />
                        </div>
                        <div className="w-full md:w-6/12 p-3">
                            <label htmlFor="imagePosition" className="label">
                                Category Position
                            </label>
                            <select
                                name="imagePosition"
                                id="imagePosition"
                                className="form-control"
                                onChange={handleInput}
                                value={state.imagePosition}
                            >
                                <option value="left">Left</option>
                                <option value="center">Center</option>
                                <option value="right">Right</option>
                            </select>
                        </div>
                        <div className="w-full p-3">
                            <label htmlFor="image" className="label">
                                Image
                            </label>
                            <input
                                type="file"
                                accept="image/*"
                                name="image"
                                id="image"
                                className="input-file"
                                onChange={handleImageUpload}
                            />
                        </div>
                        <div className="w-full p-3">
                            <input
                                type="submit"
                                value={response.isLoading ? "Loading..." : "Save Home Category"}
                                disabled={response.isLoading}
                                className="btn btn-indigo"
                            />
                        </div>
                    </div>
                </form>
                <div className="w-full xl:w-4/12 p-3">
                    <ImagesPreview url={preview} heading="Image Preview" />
                </div>
            </div>
        </Wrapper>
    );
};

export default CreateBrand;
