import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useGetContactUsByIdQuery } from "../../store/services/contactusService";
import { useAllCategoriesQuery } from "../../store/services/categoryService";
import { useGetInformationUsersQuery } from "../../store/services/informationService";

const Footer = () => {
  const { contactusid } = useSelector((state) => state.authReducer);
	const { data:contactusdata, isFetching:contactusfetch } = useGetContactUsByIdQuery(contactusid);
	const { data:informationData, isFetching:infoLoading } = useGetInformationUsersQuery();
  const { data, isFetching } = useAllCategoriesQuery();

  return (
    <footer id="site-footer" className="site-footer background four-columns">
      <div className="footer">
        <div className="section-padding">
          <div className="section-container">
            <div className="block-widget-wrap">
              <div className="row">
                <div className="col-lg-3 col-md-6 column-1">
                  <div className="block block-menu m-b-20">
                    <h2 className="block-title">Get in Touch</h2>
                    <div className="block-content">
                      <ul>
                        <li>
                          <span>Address:</span>{contactusdata?.address}     
                        </li>
                        <li>
                          <span>Phone:</span>{" "}
                          <a 
                          href={`https://wa.me/${contactusdata?.whatsappNumber.replace(/\D/g, '')}`} 
                          target="_blank" 
                          rel="noopener noreferrer"
                          >
                          {contactusdata?.whatsappNumber}
                          </a>
                        </li>
                        <li>
                          <span>Email:</span>{" "}
                          <Link target="_blank" to={`mailto:${contactusdata?.emailAddress}`}>
                          {contactusdata?.emailAddress}
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="block block-social">
                    <h2 className="block-title">Follow Us</h2>
                    <ul className="social-link">
                      {contactusdata?.facebookPageLink && (
                        <li>
                          <a href={contactusdata?.facebookPageLink} target="_blank" rel="noopener noreferrer" aria-label="Facebook">
                            <i className="fa fa-facebook" />
                          </a>
                        </li>
                      )}
                      {contactusdata?.twitterPageLink && (
                        <li>
                          <a href={contactusdata?.twitterPageLink} target="_blank" rel="noopener noreferrer" aria-label="Twitter">
                            <i className="fa fa-twitter" />
                          </a>
                        </li>
                      )}
                      {contactusdata?.linkedinPageLink && (
                        <li>
                          <a href={contactusdata?.linkedinPageLink} target="_blank" rel="noopener noreferrer" aria-label="LinkedIn">
                            <i className="fa fa-linkedin" />
                          </a>
                        </li>
                      )}
                      {contactusdata?.instagramPageLink && (
                        <li>
                          <a href={contactusdata?.instagramPageLink} target="_blank" rel="noopener noreferrer" aria-label="Instagram">
                            <i className="fa fa-instagram" />
                          </a>
                        </li>
                      )}
                      {contactusdata?.youtubePageLink && (
                        <li>
                          <a href={contactusdata?.youtubePageLink} target="_blank" rel="noopener noreferrer" aria-label="YouTube">
                            <i className="fa fa-youtube" />
                          </a>
                        </li>
                      )}
                      {contactusdata?.pinterestPageLink && (
                        <li>
                          <a href={contactusdata?.pinterestPageLink} target="_blank" rel="noopener noreferrer" aria-label="Pinterest">
                            <i className="fa fa-pinterest" />
                          </a>
                        </li>
                      )}
                      {contactusdata?.googlePlusPageLink && (
                        <li>
                          <a href={contactusdata?.googlePlusPageLink} target="_blank" rel="noopener noreferrer" aria-label="Google+">
                            <i className="fa fa-google-plus" />
                          </a>
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 column-2">
                  <div className="block block-menu">
                    <h2 className="block-title">Customer Services</h2>
                    <div className="block-content">
                      <ul>
                        <li>
                          <Link to="/Contact">Contact Us</Link>
                        </li>
                        <li>
                          <Link to="/try-at-home">Book an Appointment</Link>
                        </li>
                        <li>
                          <Link to="/Blog">Blogs</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 column-3">
                  <div className="block block-menu">
                    <h2 className="block-title">About Us</h2>
                    <div className="block-content">
                      <ul>
                        <li>
                          <Link to="/AboutUs">About Us</Link>
                        </li>
                        {informationData?.map((info, index) => {
                              return (
                                <li>
                                  <Link
                                    to={`/policies/${info.url}`}
                                  >
                                    
                                      {info.title}
                                    
                                  </Link>
                                </li>
                              );
                            })}
                       
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 column-4">
                  <div className="block block-menu">
                    <h2 className="block-title">Shop by Category</h2>
                    <div className="block-content">
                      <ul>
                      {data?.categories.map((category, index) => {
                              return (
                                <li>
                                  <Link
                                    to={`/Shop/${category.name}/${category._id}`}
                                  >
                                    
                                      {category.name}
                                    
                                  </Link>
                                </li>
                              );
                            })}
                        {/* <li>
                          <Link to="/Shop">Earrings</Link>
                        </li>
                        <li>
                          <Link to="/Shop">Necklaces</Link>
                        </li>
                        <li>
                          <Link to="/Shop">Bracelets</Link>
                        </li>
                        <li>
                          <Link to="/Shop">Rings</Link>
                        </li>
                        <li>
                          <Link to="/Shop">Jewelry Box</Link>
                        </li>
                        <li>
                          <Link to="/Shop">Studs</Link>
                        </li> */}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <div className="section-padding">
          <div className="section-container">
            <div className="block-widget-wrap">
              <div className="row">
                <div className="col-md-6">
                  <div className="footer-left">
                    <p className="copyright">
                      &copy; 2024 Fine1Jewels. All Rights Reserved.
                    </p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="footer-right">
                    <div className="block block-image">
                      <img
                        width={309}
                        height={32}
                        src="/media/payments.png"
                        alt="Payment Methods"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
