// import { useState, useEffect } from "react";
// import { Link, useNavigate } from "react-router-dom";
// import { useDispatch } from "react-redux";
// import { TwitterPicker } from "react-color";
// import { v4 as uuidv4 } from 'uuid';
// import ReactQuill from "react-quill";
// import toast, { Toaster } from 'react-hot-toast';
// import 'react-quill/dist/quill.snow.css';
// import ScreenHeader from "../../components/ScreenHeader";
// import Wrapper from "./Wrapper";
// import { useAllCategoriesQuery } from "../../store/services/categoryService";
// import { useCProductMutation } from "../../store/services/productService";
// import Spinner from "../../components/Spinner";
// import Colors from "../../components/Colors";
// import SizesList from "../../components/SizesList";
// import ImagesPreview from "../../components/ImagesPreview";
// import { setSuccess } from "../../store/reducers/globalReducer";
// import { useGetBrandsQuery } from "../../store/services/brandService";
// import { useCreateSizeMutation, useGetAllSizesQuery, useRemoveSizeMutation } from "../../store/services/sizeService";
// import { AiOutlineDelete } from 'react-icons/ai';

// const CreateProduct = () => {
//     const { data: allsizes, error, isLoading } = useGetAllSizesQuery();
//     const [createSize] = useCreateSizeMutation();
//     const [removeSize] = useRemoveSizeMutation();
//     const [submitting, setsubmitting] = useState(false)

//     const [sizes, setSizes] = useState([
//         { name: '26', price: 0 }, { name: '28', price: 0 }, { name: '30', price: 0 },
//         { name: '32', price: 0 }, { name: '34', price: 0 }, { name: '36', price: 0 },
//         { name: '38', price: 0 }, { name: '40', price: 0 }, { name: '42', price: 0 },
//         { name: '44', price: 0 }
//     ]);

//     useEffect(() => {
//         if (allsizes) {
//             setSizes(allsizes.map(size => ({ name: size.name, _id: size._id, price: 0 })));
//         }
//     }, [allsizes]);

//     const { data = [], isFetching } = useAllCategoriesQuery();
//     const { data: allbrands = [], isFetching: fetchingbrands } = useGetBrandsQuery();
//     const [value, setValue] = useState('');
//     const [state, setState] = useState({
//         title: '',
//         discount: 0,
//         stock: 100,
//         category: '',
//         colors: [],
//         shipping: 0,
//         shipdetails: '',
//         brandName: '',
//         trendingProduct: false,
//     });

//     const [metalDetails, setMetalDetails] = useState({
//         type: '',
//         karat: 0,
//         grossWeight: 0,
//         netWeight: 0,
//         wastagePercentage: 0,
//         pureGoldWeight: 0,
//         metalRate: 0,
//         metalPrice: 0
//     });

//     const [stoneDetails, setStoneDetails] = useState({
//         diamond: {
//             pcs: 0,
//             carats: 0,
//             ratePerCarat: 0,
//             amount: 0
//         },
//         otherStones: []
//     });

//     const [labourDetails, setLabourDetails] = useState({
//         ratePerGram: 0,
//         labourAmount: 0
//     });

//     const [pricing, setPricing] = useState({
//         totalPrice: 0,
//         gst: 0,
//         finalPrice: 0
//     });

//     const [sizeList, setSizeList] = useState([]);
//     const [customSize, setCustomSize] = useState('');
//     const [images, setImages] = useState([]);
//     const [preview, setPreview] = useState([]);

//     const handleInput = e => {
//         setState({ ...state, [e.target.name]: e.target.value });
//     };

//     const handleMetalDetailsChange = e => {
//         setMetalDetails({ ...metalDetails, [e.target.name]: e.target.value });
//     };

//     const handleStoneDetailsChange = (e, type, index) => {
//         if (type === 'diamond') {
//             setStoneDetails({
//                 ...stoneDetails,
//                 diamond: { ...stoneDetails.diamond, [e.target.name]: e.target.value }
//             });
//         } else {
//             const updatedOtherStones = [...stoneDetails.otherStones];
//             updatedOtherStones[index] = { ...updatedOtherStones[index], [e.target.name]: e.target.value };
//             setStoneDetails({ ...stoneDetails, otherStones: updatedOtherStones });
//         }
//     };

//     const handleLabourDetailsChange = e => {
//         setLabourDetails({ ...labourDetails, [e.target.name]: e.target.value });
//     };

//     const handlePricingChange = e => {
//         setPricing({ ...pricing, [e.target.name]: e.target.value });
//     };

//     const addOtherStone = () => {
//         setStoneDetails({
//             ...stoneDetails,
//             otherStones: [...stoneDetails.otherStones, { name: '', pcs: 0, carats: 0, ratePerCarat: 0, amount: 0 }]
//         });
//     };

//     const handleSizePriceChange = (sizeName, price) => {
//         setSizeList(sizeList.map(size => size.name === sizeName ? { ...size, price: parseFloat(price) } : size));
//     };

//     const saveColors = (color) => {
//         const filtered = state.colors.filter((clr) => clr.color !== color.hex);
//         setState({ ...state, colors: [...filtered, { color: color.hex, id: uuidv4() }] });
//     };

//     const deleteColor = color => {
//         const filtered = state.colors.filter(clr => clr.color !== color.color);
//         setState({ ...state, colors: filtered });
//     };

//     const chooseSize = sizeObject => {
//         if (!sizeList.find(size => size.name === sizeObject.name)) {
//             setSizeList([...sizeList, { ...sizeObject, price: 0 }]);
//         }
//     };

//     const deleteSize = name => {
//         const filtered = sizeList.filter(size => size.name !== name);
//         setSizeList(filtered);
//     };

//     const handleCustomSizeChange = e => {
//         setCustomSize(e.target.value);
//     };

//     const addCustomSize = async () => {
//         const sizeData = { name: customSize };
//         await createSize(sizeData);
//         if (customSize && !sizeList.find(size => size.name === customSize)) {
//             setSizeList([...sizeList, { name: customSize, price: 0 }]);
//             setCustomSize('');
//         } else {
//             toast.error('Size already exists or is invalid');
//         }
//     };

//     const handleImageChange = e => {
//         const files = Array.from(e.target.files);
//         setImages(files);

//         const filePreviews = files.map(file => {
//             const reader = new FileReader();
//             reader.readAsDataURL(file);
//             return new Promise(resolve => {
//                 reader.onloadend = () => resolve(reader.result);
//             });
//         });

//         Promise.all(filePreviews).then(previews => setPreview(previews));
//     };

//     const [createNewProduct, response] = useCProductMutation();
//     const createPro = e => {
//         e.preventDefault();
//         const formData = new FormData();
//         formData.append('data', JSON.stringify(state));
//         formData.append('metalDetails', JSON.stringify(metalDetails));
//         formData.append('stoneDetails', JSON.stringify(stoneDetails));
//         formData.append('labourDetails', JSON.stringify(labourDetails));
//         formData.append('pricing', JSON.stringify(pricing));
//         formData.append('sizes', JSON.stringify(sizeList));
//         formData.append('description', value);
//         images.forEach((image, index) => {
//             formData.append('images', image);
//         });
//         createNewProduct(formData);
//     };

//     useEffect(() => {
//         if (!response.isSuccess) {
//             response?.error?.data?.errors.map(err => {
//                 toast.error(err.msg);
//             });
//         }
//     }, [response?.error?.data?.errors]);

//     const dispatch = useDispatch();
//     const navigate = useNavigate();

//     useEffect(() => {
//         if (response?.isSuccess) {
//             dispatch(setSuccess(response?.data?.msg));
//             navigate('/dashboard/products');
//         }
//     }, [response?.isSuccess]);

//     const handleTrendingChange = () => {
//         setState({ ...state, trendingProduct: !state.trendingProduct });
//     };

//     return (
       
//         <Wrapper>
//             <ScreenHeader>
//                 <Link to="/dashboard/products" className="btn-dark">
//                     <i className="bi bi-arrow-left-short"></i> products list
//                 </Link>
//             </ScreenHeader>
//             <Toaster position="top-right" reverseOrder={true} />
//             <div className="flex flex-wrap -mx-3">
//                 <form className="w-full xl:w-8/12 p-3" onSubmit={createPro}>
//                     <div className="flex flex-wrap">
//                         <div className="w-full md:w-6/12 p-3">
//                             <label htmlFor="title" className="label">title</label>
//                             <input type="text" name="title" className="form-control" id="title" placeholder="title..." onChange={handleInput} value={state.title} />
//                         </div>
//                         <div className="w-full md:w-6/12 p-3">
//                             <label htmlFor="discount" className="label">discount</label>
//                             <input type="number" name="discount" className="form-control" id="discount" placeholder="discount..." onChange={handleInput} value={state.discount} />
//                         </div>
//                         <div className="w-full md:w-6/12 p-3">
//                             <label htmlFor="stock" className="label">stock</label>
//                             <input type="number" name="stock" className="form-control" id="stock" placeholder="stock..." onChange={handleInput} value={state.stock} />
//                         </div>
//                         <div className="w-full md:w-6/12 p-3">
//                             <label htmlFor="shipping" className="label">shipping</label>
//                             <input type="number" name="shipping" className="form-control" id="shipping" placeholder="shipping..." onChange={handleInput} value={state.shipping} />
//                         </div>
//                         <div className="w-full md:w-6/12 p-3">
//                             <label htmlFor="shipdetails" className="label">shipdetails</label>
//                             <input type="text" name="shipdetails" className="form-control" id="shipdetails" placeholder="shipdetails..." onChange={handleInput} value={state.shipdetails} />
//                         </div>
//                         <div className="w-full md:w-6/12 p-3">
//                             <label htmlFor="category" className="label">category</label>
//                             {isFetching ? <Spinner /> : data?.categories?.length > 0 && (
//                                 <select className="form-control" name="category" id="category" onChange={handleInput} value={state.category}>
//                                     <option value="">Choose category</option>
//                                     {data?.categories?.map((category) => (
//                                         <option value={category._id} key={category._id}>{category.name}</option>
//                                     ))}
//                                 </select>
//                             )}
//                         </div>
//                         <div className="w-full md:w-6/12 p-3">
//                             <label htmlFor="brand" className="label">brand</label>
//                             {fetchingbrands ? <Spinner /> : allbrands?.brands?.length > 0 && (
//                                 <select className="form-control" name="brandName" id="brandName" onChange={handleInput} value={state.brandName}>
//                                     <option value="">Choose brand</option>
//                                     {allbrands?.brands?.map((brand) => (
//                                         <option value={brand.name} key={brand._id}>{brand.name}</option>
//                                     ))}
//                                 </select>
//                             )}
//                         </div>
//                         <div className="w-full md:w-6/12 p-3">
//                             <label className="label">Trending Product</label>
//                             <input type="checkbox" checked={state.trendingProduct} onChange={handleTrendingChange} />
//                         </div>
//                     </div>
//                     <div className="w-full p-3">
//                         <label className="label">Description</label>
//                         <ReactQuill theme="snow" value={value} onChange={setValue} />
//                     </div>

//                         <div className="w-full p-3">
//                             <label htmlFor="images" className="label">Upload Images</label>
//                             <input type="file" name="images" id="images" className="input-file" onChange={handleImageChange} multiple />
//                         </div>
                       



//                     <div className="container">
//       {/* Metal Details Section */}
//       <div className="w-full p-3">
//         <label className="label">Metal Details</label>
//         <div className="flex flex-wrap">
//           {/* Metal Details Fields */}
//           {Object.keys(metalDetails).map((key) => (
//             <div key={key} className="w-full md:w-4/12 p-3">
//                 <label className="label">{key}</label>
//               <input
              
              
//                 type={key === 'karat' || key.includes('Weight') || key.includes('Rate') || key.includes('Price') ? 'number' : 'text'}
//                 name={key}
//                 className="form-control"
//                 placeholder={key.charAt(0).toUpperCase() + key.slice(1).replace(/([A-Z])/g, ' $1')}
//                 value={metalDetails[key]}
//                 onChange={handleMetalDetailsChange}
//               />
//             </div>
//           ))}
//         </div>
//       </div>

//       {/* Stone Details Section */}
//       <div className="w-full p-3">
//         <label className="label">Stone Details</label>
//         <div className="flex flex-wrap">
//           {/* Diamond Details */}
//           {Object.keys(stoneDetails.diamond).map((key) => (
//             <div key={key} className="w-full md:w-4/12 p-3">
//                 <label className="label">{key}</label>
//               <input
//                 type="number"
//                 name={key}
//                 className="form-control"
//                 placeholder={`Diamond ${key.charAt(0).toUpperCase() + key.slice(1)}`}
//                 value={stoneDetails.diamond[key]}
//                 onChange={(e) => handleStoneDetailsChange(e, 'diamond')}
//               />
//             </div>
//           ))}

//           {/* Other Stones Details */}
//           {stoneDetails.otherStones.map((stone, index) => (
//             <div key={index} className="w-full flex flex-wrap p-3">
//               {Object.keys(stone).map((key) => (
//                 <div key={key} className="w-full md:w-6/12 p-3">
//                 <label className="label">{key}</label>
//                 <input
//                   key={key}
//                   type={key === 'name' ? 'text' : 'number'}
//                   name={key}
//                   className="form-control w-1/3 p-2"
//                   placeholder={`Stone ${key.charAt(0).toUpperCase() + key.slice(1)}`}
//                   value={stone[key]}
//                   onChange={(e) => handleStoneDetailsChange(e, 'other', index)}
//                 />
//                 </div>
//               ))}
//               <div  className="w-full md:w-6/12 p-3">
              
//               <button
//                 type="button"
//                 className="btn btn-danger"
//                 onClick={() => setStoneDetails({
//                   ...stoneDetails,
//                   otherStones: stoneDetails.otherStones.filter((_, i) => i !== index),
//                 })}
//               >
//                 <AiOutlineDelete />
//               </button>
//             </div>
//             </div>
//           ))}

//           {/* Add Another Stone Button */}
//           <div className="w-full p-3">
//             <button type="button" className="btn btn-secondary" onClick={addOtherStone}>
//               Add Another Stone
//             </button>
//           </div>
//         </div>
//       </div>

//       {/* Labour Details Section */}
//       <div className="w-full p-3">
//         <label className="label">Labour Details</label>
//         <div className="flex flex-wrap">
//           {/* Labour Details Fields */}
//           {Object.keys(labourDetails).map((key) => (
//             <div key={key} className="w-full md:w-6/12 p-3">
//                 <label className="label">{key}</label>
//               <input
//                 type="number"
//                 name={key}
//                 className="form-control"
//                 placeholder={key === 'ratePerGram' ? 'Rate Per Gram' : 'Labour Amount'}
//                 value={labourDetails[key]}
//                 onChange={handleLabourDetailsChange}
//               />
//             </div>
//           ))}
//         </div>
//       </div>
//     </div>
//                     {/* <div className="w-full p-3">
//                         <label className="label">Additional Details</label>
//                         <textarea className="form-control" rows="3" placeholder="Additional details" onChange={handleAdditionalDetailsChange} value={additionalDetails}></textarea>
//                     </div> */}

                    
//                     <div className="w-full p-3">
//                         <button type="submit" className="btn btn-primary" disabled={submitting}>
//                             {submitting ? "Submitting..." : "Submit"}
//                         </button>
//                     </div>
//             </form>

//                 <div className="w-full xl:w-4/12 p-3">
//                     <Colors colors={state.colors} deleteColor={deleteColor} />
//                     <SizesList list={sizeList} deleteSize={deleteSize} />
//                     {preview.map((url, index) => (
//                         <ImagesPreview key={index} url={url} heading={`Image ${index + 1}`} />
//                     ))}
//                 </div>
           

//                 </div>
//                 </Wrapper>
//   );
// };

// export default CreateProduct;
































































import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { TwitterPicker } from "react-color";
import { v4 as uuidv4 } from 'uuid';
import ReactQuill from "react-quill";
import toast, { Toaster } from 'react-hot-toast';
import 'react-quill/dist/quill.snow.css';
import ScreenHeader from "../../components/ScreenHeader";
import Wrapper from "./Wrapper";
import { useAllCategoriesQuery } from "../../store/services/categoryService";
import { useCProductMutation } from "../../store/services/productService";
import Spinner from "../../components/Spinner";
import Colors from "../../components/Colors";
import ImagesPreview from "../../components/ImagesPreview";
import { setSuccess } from "../../store/reducers/globalReducer";
import { useGetBrandsQuery } from "../../store/services/brandService";
import { AiOutlineDelete } from 'react-icons/ai';

const CreateProduct = () => {
    const [submitting, setSubmitting] = useState(false);
        const { data = [], isFetching } = useAllCategoriesQuery();
        const { data: allbrands = [], isFetching: fetchingbrands } = useGetBrandsQuery();
    const [description, setDescription] = useState('');
    const [hasMultipleSizes, setHasMultipleSizes] = useState(false);

    const [state, setState] = useState({
        title: '',
        discount: 0,
        stock: 100,
        category: '',
        colors: [],
        shipping: 0,
        shipdetails: '',
        brandName: '',
        trendingProduct: false,
    });

    const [metalDetails, setMetalDetails] = useState({
        grossWeight: 0,
        netWeight: 0,
        wastagePercentage: 0,
        pureGoldWeight: 0,
        karats: [{ karat: 0, metalRate: 0, metalPrice: 0 }]
    });

    const [stoneDetails, setStoneDetails] = useState({
        diamond: { pcs: 0, carats: 0, ratePerCarat: 0, amount: 0 },
        otherStones: []
    });

    const [labourDetails, setLabourDetails] = useState({
        ratePerGram: 0,
        labourAmount: 0
    });

    const [pricing, setPricing] = useState({
        totalPrice: 0,
        gst: 0,
        finalPrice: 0
    });

    const [images, setImages] = useState([]);
    const [preview, setPreview] = useState([]);

    const handleInput = e => {
        setState({ ...state, [e.target.name]: e.target.value });
    };

    const handleMetalDetailsChange = (e, index) => {
        const { name, value } = e.target;
        setMetalDetails(prev => ({
            ...prev,
            karats: prev.karats.map((k, i) => i === index ? { ...k, [name]: parseFloat(value) } : k)
        }));
    };

    const handleStoneDetailsChange = (e, type, index) => {
        const { name, value } = e.target;
        if (type === 'diamond') {
            setStoneDetails(prev => ({
                ...prev,
                diamond: { ...prev.diamond, [name]: parseFloat(value) }
            }));
        } else {
            setStoneDetails(prev => ({
                ...prev,
                otherStones: prev.otherStones.map((stone, i) => 
                    i === index ? { ...stone, [name]: parseFloat(value) } : stone
                )
            }));
        }
    };

    const handleLabourDetailsChange = e => {
        const { name, value } = e.target;
        setLabourDetails(prev => ({ ...prev, [name]: parseFloat(value) }));
    };

    const handlePricingChange = e => {
        const { name, value } = e.target;
        setPricing(prev => ({ ...prev, [name]: parseFloat(value) }));
    };

    const addOtherStone = () => {
        setStoneDetails(prev => ({
            ...prev,
            otherStones: [...prev.otherStones, { name: '', pcs: 0, carats: 0, ratePerCarat: 0, amount: 0 }]
        }));
    };

    const handleImageChange = e => {
        const files = Array.from(e.target.files);
        setImages(files);
        Promise.all(files.map(file => {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onloadend = () => resolve(reader.result);
                reader.onerror = reject;
                reader.readAsDataURL(file);
            });
        })).then(results => setPreview(results));
    };

    const [createNewProduct, response] = useCProductMutation();
    const dispatch = useDispatch();
    const navigate = useNavigate();


    const [sizes, setSizes] = useState([]);
    const createProduct = e => {
        e.preventDefault();
        setSubmitting(true);
        const formData = new FormData();
        formData.append('data', JSON.stringify({ ...state, multiplesize: hasMultipleSizes,sizes: sizes }));
        formData.append('metalDetails', JSON.stringify(metalDetails));
        formData.append('stoneDetails', JSON.stringify(stoneDetails));
        formData.append('labourDetails', JSON.stringify(labourDetails));
        formData.append('pricing', JSON.stringify(pricing));
        formData.append('description', description);
        images.forEach(image => formData.append('images', image));
        createNewProduct(formData);
    };

    const handleSizeChange = (e, index) => {
        const { name, value } = e.target;
        setSizes(prevSizes => {
            return prevSizes.map((size, i) => {
                if (i === index) {
                    return { ...size, [name]: value };
                }
                return size;
            });
        });
    };
    
    const addSize = () => {
        setSizes(prevSizes => [...prevSizes, { size: '', grossWeight: '', netWeight: '', name: '' }]);
    };
    
    const removeSize = (index) => {
        setSizes(prevSizes => prevSizes.filter((_, i) => i !== index));
    };

    const removeKarat = (index) => {
        setMetalDetails({
          ...metalDetails,
          karats: metalDetails.karats.filter((_, i) => i !== index),
        });
      };
    

    useEffect(() => {
        if (response.isError) {
            setSubmitting(false);
            response.error.data.errors.forEach(err => toast.error(err.msg));
        }
        if (response.isSuccess) {
            setSubmitting(false);
            dispatch(setSuccess(response.data.msg));
            navigate('/dashboard/products');
        }
    }, [response]);

    return (
        <Wrapper>
            <ScreenHeader>
                <Link to="/dashboard/products" className="btn-dark">
                    <i className="bi bi-arrow-left-short"></i> Products List
                </Link>
            </ScreenHeader>
            <Toaster position="top-right" reverseOrder={false} />
            <form className="w-full xl:w-8/12 p-3" onSubmit={createProduct}>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div>
                        <label htmlFor="title" className="block text-sm font-medium text-white">Title</label>
                        <input type="text" name="title" id="title" className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" onChange={handleInput} value={state.title} required />
                    </div>
                    <div>
                        <label htmlFor="discount" className="block text-sm font-medium text-white">Discount (%)</label>
                        <input type="number" name="discount" id="discount" className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" onChange={handleInput} value={state.discount} />
                    </div>
                    <div>
                        <label htmlFor="stock" className="block text-sm font-medium text-white">Stock</label>
                        <input type="number" name="stock" id="stock" className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" onChange={handleInput} value={state.stock} required />
                    </div>
                    <div>
                        <label htmlFor="category" className="block text-sm font-medium text-white">Category</label>
                        <select id="category" name="category" className="mt-1 block w-full py-2 px-3 border border-gray-300  rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" onChange={handleInput} value={state.category} required>
                            <option value="">Select a category</option>
                              
                                {data?.categories?.map((category) => (
    <option value={category._id} key={category._id}>
      {category.name}
    </option>
  ))}
                        </select>
                    </div>
                    <div>
                        <label htmlFor="brandName" className="block text-sm font-medium text-white">Brand</label>
                        <select id="brandName" name="brandName" className="mt-1 block w-full py-2 px-3 border border-gray-300 text-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" onChange={handleInput} value={state.brandName} required>
                            <option value="">Select a brand</option>
                                                        {/* { allbrands?.brands?.length > 0 && ( */}
                              
                                    {allbrands?.brands?.map((brand) => (
                                        <option value={brand.name} key={brand._id}>{brand.name}</option>
                                    ))}
                            {/* )} */}
                        </select>
                    </div>
                    <div>
                        <label htmlFor="shipping" className="block text-sm font-medium text-white">Shipping Cost</label>
                        <input type="number" name="shipping" id="shipping" className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" onChange={handleInput} value={state.shipping} />
                    </div>
                </div>

                <div className="mt-4">
                    <label htmlFor="shipdetails" className="block text-sm font-medium text-white">Shipping Details</label>
                    <textarea name="shipdetails" id="shipdetails" rows="3" className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" onChange={handleInput} value={state.shipdetails}></textarea>
                </div>

                <div className="mt-4">
                    <label className="block text-sm font-medium text-white">Colors</label>
                    <Colors colors={state.colors} setColors={setState} />
                </div>

                <div className="mt-4">
                    <label className="inline-flex items-center">
                        <input type="checkbox" className="form-checkbox h-5 w-5 text-indigo-600" checked={hasMultipleSizes} onChange={() => setHasMultipleSizes(!hasMultipleSizes)} />
                        <span className="ml-2 text-white">Multiple Sizes</span>
                    </label>
                </div>

                {hasMultipleSizes && (
                   <div className="mt-4">
                   <label className="block text-sm font-medium text-white">Size Details</label>
                   <div className="grid grid-cols-1 gap-4">
                       {sizes.map((size, index) => (
                           <div key={index} className="grid grid-cols-6 gap-4">
                               <input type="number" name="size" className="form-input mt-1 block w-full" placeholder="Size" onChange={e => handleSizeChange(e, index)} value={size.size} required />
                               <input type="number" name="grossWeight" className="form-input mt-1 block w-full" placeholder="Gross Weight" onChange={e => handleSizeChange(e, index)} value={size.grossWeight} />
                               <input type="number" name="netWeight" className="form-input mt-1 block w-full" placeholder="Net Weight" onChange={e => handleSizeChange(e, index)} value={size.netWeight} />
                               <input type="number" name="pureGoldWeight" className="form-input mt-1 block w-full" placeholder="Pure Gold Weight" onChange={e => handleSizeChange(e, index)} value={size.pureGoldWeight} />
                               <input type="text" name="name" className="form-input mt-1 block w-full" placeholder="Name" onChange={e => handleSizeChange(e, index)} value={size.name} />
                               <button type="button" className="btn btn-danger" onClick={() => removeSize(index)}>Remove</button>
                           </div>
                       ))}
                   </div>
                   <button type="button" className="btn btn-primary mt-2" onClick={addSize}>Add Size</button>
               </div>
               
                )}

                <div className="mt-4">
                    <label htmlFor="description" className="block text-sm font-medium text-white">Description</label>
                    <ReactQuill theme="snow" value={description} onChange={setDescription} className="mt-1" />
                </div>

                <div className="mt-6">
                    <h3 className="text-xl font-bold text-white mb-2">Metal Details</h3>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <div>
                            <label htmlFor="grossWeight" className="block text-sm font-medium text-white">Gross Weight (grams)</label>
                            <input type="number" name="grossWeight" id="grossWeight" className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" onChange={e => setMetalDetails({...metalDetails, grossWeight: parseFloat(e.target.value)})} value={metalDetails.grossWeight} />
                        </div>
                        <div>
                            <label htmlFor="netWeight" className="block text-sm font-medium text-white">Net Weight (grams)</label>
                            <input type="number" name="netWeight" id="netWeight" className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" onChange={e => setMetalDetails({...metalDetails, netWeight: parseFloat(e.target.value)})} value={metalDetails.netWeight} />
                        </div>
                        <div>
                            <label htmlFor="wastagePercentage" className="block text-sm font-medium text-white">Wastage Percentage (%)</label>
                            <input type="number" name="wastagePercentage" id="wastagePercentage" className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" onChange={e => setMetalDetails({...metalDetails, wastagePercentage: parseFloat(e.target.value)})} value={metalDetails.wastagePercentage} />
                        </div>
                        <div>
                            <label htmlFor="pureGoldWeight" className="block text-sm font-medium text-white">Pure Gold Weight (grams)</label>
                            <input type="number" name="pureGoldWeight" id="pureGoldWeight" className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" onChange={e => setMetalDetails({...metalDetails, pureGoldWeight: parseFloat(e.target.value)})} value={metalDetails.pureGoldWeight} />
                        </div>
                    </div>

                    <div className="mt-4">
                        <h4 className="text-xl font-bold text-white mb-2">Karats</h4>
                       {metalDetails.karats.map((karat, index) => (
  <div key={index} className="grid grid-cols-3 gap-4 mb-4">
    <div>
      <label htmlFor={`karat${index}`} className="block text-sm font-medium text-white">Karat</label>
      <input
        type="number"
        name="karat"
        id={`karat${index}`}
        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
        onChange={(e) => handleMetalDetailsChange(e, index)}
        value={karat.karat}
      />
    </div>
    <div>
      <label htmlFor={`metalRate${index}`} className="block text-sm font-medium text-white">Metal Rate (per gram)</label>
      <input
        type="number"
        name="metalRate"
        id={`metalRate${index}`}
        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
        onChange={(e) => handleMetalDetailsChange(e, index)}
        value={karat.metalRate}
      />
    </div>
    <div>
      <label htmlFor={`metalPrice${index}`} className="block text-sm font-medium text-white">Metal Price</label>
      <input
        type="number"
        name="metalPrice"
        id={`metalPrice${index}`}
        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
        onChange={(e) => handleMetalDetailsChange(e, index)}
        value={karat.metalPrice}
      />
    </div>

    <div  className="w-full md:w-6/12 p-3">
              
              <button
                             type="button"
                             className="btn btn-danger"
                             onClick={() => removeKarat(index)}
                           >
                             <AiOutlineDelete />
                           </button>
                         </div>
  
  </div>
))}
<button
  type="button"
  className="mt-2 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
  onClick={() => setMetalDetails({ ...metalDetails, karats: [...metalDetails.karats, { karat: 0, metalRate: 0, metalPrice: 0 }] })}
>
  Add Karat
</button>
                </div>
                </div>

                <div className="mt-6">
                    <h3 className="text-xl font-bold text-white mb-2">Stone Details</h3>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <div>
                            <label htmlFor="diamondPcs" className="block text-sm font-medium text-white">Diamond Pieces</label>
                            <input type="number" name="pcs" id="diamondPcs" className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" onChange={e => handleStoneDetailsChange(e, 'diamond')} value={stoneDetails.diamond.pcs} />
                        </div>
                        <div>
                            <label htmlFor="diamondCarats" className="block text-sm font-medium text-white">Diamond Carats</label>
                            <input type="number" name="carats" id="diamondCarats" className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" onChange={e => handleStoneDetailsChange(e, 'diamond')} value={stoneDetails.diamond.carats} />
                        </div>
                        <div>
                            <label htmlFor="diamondRatePerCarat" className="block text-sm font-medium text-white">Diamond Rate Per Carat</label>
                            <input type="number" name="ratePerCarat" id="diamondRatePerCarat" className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" onChange={e => handleStoneDetailsChange(e, 'diamond')} value={stoneDetails.diamond.ratePerCarat} />
                        </div>
                        <div>
                            <label htmlFor="diamondAmount" className="block text-sm font-medium text-white">Diamond Amount</label>
                            <input type="number" name="amount" id="diamondAmount" className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" onChange={e => handleStoneDetailsChange(e, 'diamond')} value={stoneDetails.diamond.amount} />
                        </div>
                    </div>

                    <div className="mt-4">
                        <h4 className="text-xl font-bold text-white mb-2">Other Stones</h4>
                        {stoneDetails.otherStones.map((stone, index) => (
                            <div key={index} className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-4 mb-4">
                                <div>
                                    <label htmlFor={`otherStoneName${index}`} className="block text-sm font-medium text-white">Stone Name</label>
                                    <input type="text" name="name" id={`otherStoneName${index}`} className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" onChange={e => handleStoneDetailsChange(e, 'other', index)} value={stone.name} />
                                </div>
                                <div>
                                    <label htmlFor={`otherStonePcs${index}`} className="block text-sm font-medium text-white">Pieces</label>
                                    <input type="number" name="pcs" id={`otherStonePcs${index}`} className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" onChange={e => handleStoneDetailsChange(e, 'other', index)} value={stone.pcs} />
                                </div>
                                <div>
                                    <label htmlFor={`otherStoneCarats${index}`} className="block text-sm font-medium text-white">Carats</label>
                                    <input type="number" name="carats" id={`otherStoneCarats${index}`} className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" onChange={e => handleStoneDetailsChange(e, 'other', index)} value={stone.carats} />
                                </div>
                                <div>
                                    <label htmlFor={`otherStoneRatePerCarat${index}`} className="block text-sm font-medium text-white">Rate Per Carat</label>
                                    <input type="number" name="ratePerCarat" id={`otherStoneRatePerCarat${index}`} className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" onChange={e => handleStoneDetailsChange(e, 'other', index)} value={stone.ratePerCarat} />
                                </div>
                                <div>
                                    <label htmlFor={`otherStoneAmount${index}`} className="block text-sm font-medium text-white">Amount</label>
                                    <input type="number" name="amount" id={`otherStoneAmount${index}`} className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" onChange={e => handleStoneDetailsChange(e, 'other', index)} value={stone.amount} />
                                </div>
               <div  className="w-full md:w-6/12 p-3">
              
 <button
                type="button"
                className="btn btn-danger"
                onClick={() => setStoneDetails({
                  ...stoneDetails,
                  otherStones: stoneDetails.otherStones.filter((_, i) => i !== index),
                })}
              >
                <AiOutlineDelete />
              </button>
            </div>
                            </div>
                        ))}
                        <button type="button" className="mt-2 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" onClick={addOtherStone}>
                            Add Another Stone
                        </button>
                    </div>
                </div>

                <div className="mt-6">
                    <h3 className="text-xl font-bold text-white mb-2">Labour Details</h3>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <div>
                            <label htmlFor="labourRatePerGram" className="block text-sm font-medium text-white">Labour Rate Per Gram</label>
                            <input type="number" name="ratePerGram" id="labourRatePerGram" className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" onChange={handleLabourDetailsChange} value={labourDetails.ratePerGram} />
                        </div>
                        <div>
                            <label htmlFor="labourAmount" className="block text-sm font-medium text-white">Labour Amount</label>
                            <input type="number" name="labourAmount" id="labourAmount" className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" onChange={handleLabourDetailsChange} value={labourDetails.labourAmount} />
                        </div>
                    </div>
                </div>

                <div className="mt-6">
                    <h3 className="text-xl font-bold text-white mb-2">Pricing</h3>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                        <div>
                            <label htmlFor="totalPrice" className="block text-sm font-medium text-white">Total Price</label>
                            <input type="number" name="totalPrice" id="totalPrice" className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" onChange={handlePricingChange} value={pricing.totalPrice} />
                        </div>
                        <div>
                            <label htmlFor="gst" className="block text-sm font-medium text-white">GST</label>
                            <input type="number" name="gst" id="gst" className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" onChange={handlePricingChange} value={pricing.gst} />
                        </div>
                        <div>
                            <label htmlFor="finalPrice" className="block text-sm font-medium text-white">Final Price</label>
                            <input type="number" name="finalPrice" id="finalPrice" className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" onChange={handlePricingChange} value={pricing.finalPrice} />
                        </div>
                    </div>
                </div>

                <div className="mt-6">
                    <label className="block text-sm font-medium text-white">Product Images</label>
                    <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                        <div className="space-y-1 text-center">
                            <svg className="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
                                <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            <div className="flex text-sm text-gray-600">
                                <label htmlFor="file-upload" className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
                                    <span>Upload files</span>
                                    <input id="file-upload" name="file-upload" type="file" className="sr-only" multiple onChange={handleImageChange} />
                                </label>
                                <p className="pl-1">or drag and drop</p>
                            </div>
                            <p className="text-xs text-gray-500">PNG, JPG, GIF up to 10MB</p>
                        </div>
                    </div>
                </div>

                {preview.length > 0 && (
                    <div className="mt-6 grid grid-cols-2 gap-4 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6">
                        {preview.map((url, index) => (
                            <div key={index} className="relative">
                                <img src={url} alt={`Preview ${index + 1}`} className="h-24 w-full object-cover rounded-md" />
                                <button type="button" onClick={() => {
                                    setPreview(preview.filter((_, i) => i !== index));
                                    setImages(images.filter((_, i) => i !== index));
                                }} className="absolute top-0 right-0 -mt-2 -mr-2 p-1 bg-red-500 text-white rounded-full hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">
                                    <AiOutlineDelete className="h-4 w-4" />
                                </button>
                            </div>
                        ))}
                    </div>
                )}

                <div className="mt-8">
            <Toaster position="top-right" reverseOrder={true} />
            <div className="flex flex-wrap -mx-3">
            

                {/* Preview Section */}
                <div className="w-full xl:w-4/12 p-3">
                    {preview.map((url, index) => (
                        <ImagesPreview key={index} url={url} heading={`Image ${index + 1}`} />
                    ))}
                </div>
            </div>
            </div>

            <div className="w-full p-3">
    <button type="submit" className="btn btn-primary" disabled={submitting}>
        {submitting ? "Submitting..." : "Submit"}
    </button>
</div>
            </form>
        </Wrapper>
    );
};

export default CreateProduct;