import React, { useState } from 'react';
import { FaVideo } from 'react-icons/fa';
import { useMediaQuery } from 'react-responsive';

const LiveVideoCall = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const isSmallScreen = useMediaQuery({ maxWidth: 640 });
  const [pincode, setPincode] = useState('');
  const [loading, setLoading] = useState(false); // To show loading state
  const isMediumScreen = useMediaQuery({ minWidth: 641, maxWidth: 1024 });

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setFormSubmitted(true);

    // Hide the modal after 2 seconds
    setTimeout(() => {
      setShowPopup(false);
    }, 2000);
  };


  const locateMe = () => {
    if (navigator.geolocation) {
      setLoading(true);
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;
          setPincode(latitude+" and "+longitude);
          setLoading(false)
        },
        (error) => {
          console.error('Error getting location:', error);
          setLoading(false);
        }
      );
    } else {
      alert('Geolocation is not supported by this browser.');
    }
  };


  return (
    <div
      className={`flex justify-end items-center ${
        showPopup ? 'bg-black bg-opacity-50' : ''
      }`}
    >
      <div className="flex flex-col items-start p-4 bg-white w-half">
        <div className="flex items-center mb-2">
          <span className="text-[#E5B53A] mr-2">
            <FaVideo />
          </span>
          <span className="text-gray-800 font-medium">Video Call</span>
        </div>
        <div className="text-sm text-gray-600 mb-3">
          Experience our designs up close!
        </div>
        <button
          className="w-full bg-[#E5B53A] text-black py-2 px-4 rounded-full text-sm font-medium hover:bg-[#E5B53A] transition-colors duration-300"
          onClick={togglePopup}
        >
          Schedule Call
        </button>
      </div>

      {showPopup && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-70 p-4 z-50">
          <div className="bg-white rounded-xl shadow-2xl w-full max-w-4xl overflow-hidden">
            <div className="flex justify-between items-center p-6 border-b sticky top-0 bg-white z-10">
              <h2 className="text-2xl font-bold text-gray-800">
                Schedule a Live Video Call
              </h2>
              <button
                onClick={togglePopup}
                className="text-gray-500 hover:text-gray-700 transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-gray-300 rounded-full p-1"
                aria-label="Close popup"
              >
                <svg
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            <div className="p-4 sm:p-6">
              {formSubmitted ? (
                <div className="text-center text-green-600 font-medium">
                  We will connect to you soon via Whatsapp.
                </div>
              ) : (
                <form onSubmit={handleSubmit}>
                  <div className="flex flex-col sm:flex-row gap-6">
                    <div className="w-full sm:w-1/2 space-y-4">
                      <div className="w-full space-y-4 bg-[#ebebee] rounded-lg p-4">
                        <img
                          src="https://img.freepik.com/free-vector/illustrated-best-friends-video-calling_23-2148504107.jpg"
                          alt="Video call illustration"
                          className="w-full h-48 sm:h-64 object-cover rounded-lg"
                        />
                        <p className="text-sm text-green-600 text-center">
                          Want to get a closer look now? Hop on a video call with
                          our design consultants and see your favorite designs live.
                        </p>
                      </div>
                      <div className="w-full space-y-4">
                        <p className="text-xs text-gray-500 text-center">
                          For further clarification or help, call toll-free number:{' '}
                          <a
                            href="tel:18001020103"
                            className="text-green-600 hover:underline"
                          >
                            1800-102-0103
                          </a>
                        </p>
                      </div>
                    </div>
                    <div className="w-full lg:w-1/2 space-y-6">
                      <div className="space-y-4">
                        <div className="flex items-center border rounded-md overflow-hidden focus-within:ring-2 focus-within:ring-green-500">
                          <span className="text-gray-500 px-3 py-2 bg-gray-50 border-r">
                            IN +91
                          </span>
                          <input
                            type="tel"
                            placeholder="Enter Mobile Number"
                            className="flex-grow px-3 py-2 text-sm focus:outline-none"
                            required
                          />
                        </div>
                        <div className="flex items-center border rounded-md overflow-hidden focus-within:ring-2 focus-within:ring-green-500">
                          <svg
                            className="h-5 w-5 text-gray-400 mx-3"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fillRule="evenodd"
                              d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z"
                              clipRule="evenodd"
                            />
                          </svg>
                          <input
                            type="text"
                            placeholder="Enter Pincode"
                            value={pincode}
                            onChange={(e) => setPincode(e.target.value)}
                            className="flex-grow px-3 py-2 text-sm focus:outline-none"
                            required
                          />
                          <button
                            type="button"
                            onClick={locateMe}
                            className="text-green-600 px-3 py-2 text-sm font-medium hover:bg-green-50 transition-colors duration-200 focus:outline-none focus:bg-green-100"
                          >
                            {loading ? 'Locating...' : 'LOCATE ME'}
                          </button>
                        </div>
                      </div>
                      <div>
                        <p className="text-sm font-medium text-gray-700 mb-2">
                          Preferred Language
                        </p>
                        <div className="flex flex-wrap gap-2">
                          {[
                            'English',
                            'Hindi',
                            'Tamil',
                            'Telugu',
                            'Marathi',
                            'Gujarati',
                            'Malayalam',
                            'Others',
                          ].map((lang) => (
                            <button
                              key={lang}
                              className={`px-3 py-1 rounded-full text-xs ${
                                lang === 'English'
                                  ? 'bg-purple-100 text-purple-700'
                                  : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                              } transition-colors duration-200`}
                            >
                              {lang}
                            </button>
                          ))}
                        </div>
                      </div>
                      <button
                        type="submit"
                        className="w-full bg-green-500 text-white font-semibold py-3 rounded-lg text-sm uppercase hover:bg-green-600 transition-colors duration-200 shadow-md focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                      >
                        SCHEDULE A VIDEO CALL
                      </button>
                    </div>
                  </div>
                </form>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default LiveVideoCall;
