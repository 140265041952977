
import React from 'react'

const Loader = ({isFetching}) => {
    {return isFetching ?   (
      <div className="page-preloader items-center justify-center">
         <img
                    
                    style={{width:"200px",height:'200px'}}
                    className="object-contain loader"
                    src="/media/logo-white.png"
                    alt="Fine1Jewels – Jewelry Store"
                    />
       
      </div>
  ):null}
}

export default Loader
