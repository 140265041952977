import React, { useState, useEffect } from 'react';
import { useAllCategoriesQuery } from '../../store/services/categoryService';
import { Link } from 'react-router-dom';
import { useSearchProductsQuery } from '../../store/services/homeProducts';
import currency  from "currency-formatter";
const SearchOverlay = ({ isOpen, onClose }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const { data: categoriesData, isFetching: isFetchingCategories } = useAllCategoriesQuery();
  const { data: searchProductsData, isFetching: isFetchingSearchProducts } = useSearchProductsQuery(searchTerm,{skip:!searchTerm});


  return (
    <div className="search-overlay">
      <div className="close-search" />
      <div className="wrapper-search">
        <form role="search" method="get" className="search-from ajax-search" action="" onSubmit={() => setSearchTerm(document.querySelector('.input-search').value)}>
          <a href="#" className="search-close" />
          <div className="search-box">
            <button id="searchsubmit" className="btn" type="submit">
              <i className="icon-search" />
            </button>
            <input
              type="text"
              autoComplete="off"
              name="s"
              className="input-search s"
              placeholder="Search..."
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <div className="content-menu_search">
              {searchTerm && searchProductsData?.products.length > 0 ? (
                <div>
                  <label>Search Results</label>
                  <ul id="menu_search" className="menu">
                    {searchProductsData?.products.map((product, index) => (
                      <div className="" key={index}>
                      <div className="row">
                        {/* Column for the product image */}
                        <div className="col-md-4 col-6"> {/* On mobile, use col-6 to take 50% width */}
                          <div className="products-thumb">
                            <div className="product-thumb-hover">
                              <a href={`/product/${product._id}`}>
                                <img
                                  width={100}
                                  height={100}
                                  src={`https://fine1jewels.com/images/${product.images[0]}`}
                                  className="post-image"
                                  alt=""
                                />
                              </a>
                            </div>
                          </div>
                        </div>
                        {/* Column for the product content */}
                        <div className="col-md-8 col-6"> {/* On mobile, use col-6 to take 50% width */}
                          <div className="products-content">
                            <h3 className="product-title">
                              <a href={`/product/${product._id}`}>
                              {currency.format(product.pricing.finalPrice, { code: "INR" })}
                              </a>
                            </h3>
                            <span className="price">
                            {product.name}
                              
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    
                    ))}
                  </ul>
                </div>
              ) : (
                <>
                  <label>Suggested</label>
                  <ul id="menu_search" className="menu">
                    {categoriesData?.categories.map((category, index) => (
                      <li key={index}>
                        <Link to={`/Shop/${category.name}/${category._id}`}>
                          {category.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </>
              )}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SearchOverlay;
