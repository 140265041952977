import React from 'react'
import { useParams } from 'react-router-dom';
import { useGetSingleInformationQuery } from '../../store/services/informationService';
import Loader from '../../components/Loader/Loader';

const Policies = () => {
    const { name, id } = useParams();
    const { data, isFetching } = useGetSingleInformationQuery(id);

    

    return (
        <main class="fix">
            <section class="align-items-center p-10" style={{ height: "100vh" }}>
{isFetching ?   <Loader isFetching={isFetching}/>:

                <div className="rich-text-content"
                    dangerouslySetInnerHTML={{ __html: data[0]?.details }}
                    style={{ overflowY: 'auto', maxHeight: 'calc(100vh - 100px)', color: 'black' }}
                />}

            </section>
        </main>
    )
}

export default Policies
