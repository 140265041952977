import React from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { logout } from "../../store/reducers/authReducer";

const MyAccount = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <>
      <div id="page" className="hfeed page-wrapper">
        <div id="site-main" className="site-main">
          <div id="main-content" className="main-content">
            <div id="primary" className="content-area">
              <div id="title" className="page-title">
                <div className="section-container">
                  <div className="content-title-heading">
                    <h1 className="text-title-heading">My Account</h1>
                  </div>
                  <div className="breadcrumbs">
                    <Link to="/">Home</Link>
                    <span className="delimiter" />
                    My Account
                  </div>
                </div>
              </div>
              <div id="content" className="site-content" role="main">
                <div className="section-padding">
                  <div className="section-container p-l-r">
                    <div className="page-my-account">
                      <div className="my-account-wrap clearfix">
                        <nav className="my-account-navigation">
                          <ul className="nav nav-tabs">
                            <li className="nav-item">
                              <Link
                                className="nav-link active"
                                data-toggle="tab"
                                to="#dashboard"
                                role="tab"
                              >
                                Dashboard
                              </Link>
                            </li>
                            <li className="nav-item">
                              <Link
                                className="nav-link"
                                data-toggle="tab"
                                to="#orders"
                                role="tab"
                              >
                                Orders
                              </Link>
                            </li>
                            <li className="nav-item">
                              <Link
                                className="nav-link"
                                data-toggle="tab"
                                to="#addresses"
                                role="tab"
                              >
                                Addresses
                              </Link>
                            </li>
                            <li className="nav-item">
                              <Link
                                className="nav-link"
                                data-toggle="tab"
                                to="#account-details"
                                role="tab"
                              >
                                Account details
                              </Link>
                            </li>
                            <li className="nav-item" onClick={() => {dispatch(logout('userToken'));navigate("/")}}>
                              <Link className="nav-link" >
                                Logout
                              </Link>
                            </li>
                          </ul>
                        </nav>
                        <div className="my-account-content tab-content">
                          <div
                            className="tab-pane fade show active"
                            id="dashboard"
                            role="tabpanel"
                          >
                            <div className="my-account-dashboard">
                              <p>
                                Hello <strong>Rosie</strong> (not{" "}
                                <strong>Rosie</strong>?{" "}
                                <Link to="/LoginRegister">Log out</Link>)
                              </p>
                              <p>
                                From your account dashboard you can view your{" "}
                                <Link to="#">recent orders</Link>, manage your{" "}
                                <Link to="#">shipping and billing addresses</Link>,
                                and{" "}
                                <Link to="#">
                                  edit your password and account details
                                </Link>
                                .
                              </p>
                            </div>
                          </div>
                          <div
                            className="tab-pane fade"
                            id="orders"
                            role="tabpanel"
                          >
                            <div className="my-account-orders">
                              <div className="table-responsive">
                                <table className="table">
                                  <thead>
                                    <tr>
                                      <th>Order</th>
                                      <th>Date</th>
                                      <th>Status</th>
                                      <th>Total</th>
                                      <th>Actions</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td>#1357</td>
                                      <td>March 45, 2020</td>
                                      <td>Processing</td>
                                      <td>$125.00 for 2 item</td>
                                      <td>
                                        <Link
                                          to="#"
                                          className="btn-small d-block"
                                        >
                                          View
                                        </Link>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>#2468</td>
                                      <td>June 29, 2020</td>
                                      <td>Completed</td>
                                      <td>$364.00 for 5 item</td>
                                      <td>
                                        <Link
                                          to="#"
                                          className="btn-small d-block"
                                        >
                                          View
                                        </Link>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>#2366</td>
                                      <td>August 02, 2020</td>
                                      <td>Completed</td>
                                      <td>$280.00 for 3 item</td>
                                      <td>
                                        <Link
                                          to="#"
                                          className="btn-small d-block"
                                        >
                                          View
                                        </Link>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                          <div
                            className="tab-pane fade"
                            id="addresses"
                            role="tabpanel"
                          >
                            <div className="my-account-addresses">
                              <p>
                                The following addresses will be used on the
                                checkout page by default.
                              </p>
                              <div className="addresses">
                                <div className="addresses-col">
                                  <header className="col-title">
                                    <h3>Billing address</h3>
                                    <Link to="#" className="edit">
                                      Edit
                                    </Link>
                                  </header>
                                  <address>
                                    3522 Interstate
                                    <br />
                                    75 Business Spur,
                                    <br />
                                    Sault Ste.
                                    <br />
                                    Marie, MI 49783
                                  </address>
                                </div>
                                <div className="addresses-col">
                                  <header className="col-title">
                                    <h3>Shipping address</h3>
                                    <Link to="#" className="edit">
                                      Edit
                                    </Link>
                                  </header>
                                  <address>
                                    4299 Express Lane
                                    <br />
                                    Sarasota,
                                    <br />
                                    FL 34249 USA <br />
                                    Phone: 1.941.227.4444
                                  </address>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="tab-pane fade"
                            id="account-details"
                            role="tabpanel"
                          >
                            <div className="my-account-account-details">
                              <form
                                className="edit-account"
                                action=""
                                method="post"
                              >
                                <p className="form-row">
                                  <label htmlFor="account_first_name">
                                    First name{" "}
                                    <span className="required">*</span>
                                  </label>
                                  <input
                                    type="text"
                                    className="input-text"
                                    name="account_first_name"
                                  />
                                </p>
                                <p className="form-row">
                                  <label>
                                    Last name{" "}
                                    <span className="required">*</span>
                                  </label>
                                  <input
                                    type="text"
                                    className="input-text"
                                    name="account_last_name"
                                  />
                                </p>
                                <div className="clear" />
                                <p className="form-row">
                                  <label>
                                    Display name{" "}
                                    <span className="required">*</span>
                                  </label>
                                  <input
                                    type="text"
                                    className="input-text"
                                    name="account_display_name"
                                  />
                                  <span>
                                    <em>
                                      This will be how your name will be
                                      displayed in the account section and in
                                      reviews
                                    </em>
                                  </span>
                                </p>
                                <div className="clear" />
                                <p className="form-row">
                                  <label>
                                    Email address{" "}
                                    <span className="required">*</span>
                                  </label>
                                  <input
                                    type="email"
                                    className="input-text"
                                    name="account_email"
                                  />
                                </p>
                                <fieldset>
                                  <legend>Password change</legend>
                                  <p className="form-row">
                                    <label>
                                      Current password (leave blank to leave
                                      unchanged)
                                    </label>
                                    <input
                                      type="password"
                                      className="input-text"
                                      name="password_current"
                                      autoComplete="off"
                                    />
                                  </p>
                                  <p className="form-row">
                                    <label>
                                      New password (leave blank to leave
                                      unchanged)
                                    </label>
                                    <input
                                      type="password"
                                      className="input-text"
                                      name="password_1"
                                      autoComplete="off"
                                    />
                                  </p>
                                  <p className="form-row">
                                    <label>Confirm new password</label>
                                    <input
                                      type="password"
                                      className="input-text"
                                      name="password_2"
                                      autoComplete="off"
                                    />
                                  </p>
                                </fieldset>
                                <div className="clear" />
                                <p className="form-row">
                                  <button
                                    type="submit"
                                    className="button"
                                    name="save_account_details"
                                    value="Save changes"
                                  >
                                    Save changes
                                  </button>
                                </p>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* #content */}
            </div>
            {/* #primary */}
          </div>
          {/* #main-content */}
        </div>
      </div>
      {/* Back Top button */}
      <div className="back-top button-show">
        <i className="arrow_carrot-up" />
      </div>
      {/* Search */}
      
      {/* Wishlist */}
      <div className="wishlist-popup">
        <div className="wishlist-popup-inner">
          <div className="wishlist-popup-content">
            <div className="wishlist-popup-content-top">
              <span className="wishlist-name">Wishlist</span>
              <span className="wishlist-count-wrapper">
                <span className="wishlist-count">2</span>
              </span>
              <span className="wishlist-popup-close" />
            </div>
            <div className="wishlist-popup-content-mid">
              <table className="wishlist-items">
                <tbody>
                  <tr className="wishlist-item">
                    <td className="wishlist-item-remove">
                      <span />
                    </td>
                    <td className="wishlist-item-image">
                      <Link to="/ProductDetails">
                        <img
                          width={600}
                          height={600}
                          src="media/product/3.jpg"
                          alt=""
                        />
                      </Link>
                    </td>
                    <td className="wishlist-item-info">
                      <div className="wishlist-item-name">
                        <Link to="/ProductDetails">Twin Hoops</Link>
                      </div>
                      <div className="wishlist-item-price">
                        <span>$150.00</span>
                      </div>
                      <div className="wishlist-item-time">June 4, 2022</div>
                    </td>
                    <td className="wishlist-item-actions">
                      <div className="wishlist-item-stock">In stock</div>
                      <div className="wishlist-item-add">
                        <div data-title="Add to cart">
                          <Link rel="nofollow" to="#">
                            Add to cart
                          </Link>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr className="wishlist-item">
                    <td className="wishlist-item-remove">
                      <span />
                    </td>
                    <td className="wishlist-item-image">
                      <Link to="/ProductDetails">
                        <img
                          width={600}
                          height={600}
                          src="media/product/4.jpg"
                          alt=""
                        />
                      </Link>
                    </td>
                    <td className="wishlist-item-info">
                      <div className="wishlist-item-name">
                        <Link to="/ProductDetails">
                          Yilver And Turquoise Earrings
                        </Link>
                      </div>
                      <div className="wishlist-item-price">
                        <del aria-hidden="true">
                          <span>$150.00</span>
                        </del>
                        <ins>
                          <span>$100.00</span>
                        </ins>
                      </div>
                      <div className="wishlist-item-time">June 4, 2022</div>
                    </td>
                    <td className="wishlist-item-actions">
                      <div className="wishlist-item-stock">In stock</div>
                      <div className="wishlist-item-add">
                        <div data-title="Add to cart">
                          <a rel="nofollow" href="#">
                            Add to cart
                          </a>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="wishlist-popup-content-bot">
              <div className="wishlist-popup-content-bot-inner">
                <a className="wishlist-page" href="shop-wishlist.html">
                  Open wishlist page
                </a>
                <span className="wishlist-continue" data-url="">
                  Continue shopping
                </span>
              </div>
              <div className="wishlist-notice wishlist-notice-show">
                Added to the wishlist!
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Compare */}
      <div className="compare-popup">
        <div className="compare-popup-inner">
          <div className="compare-table">
            <div className="compare-table-inner">
              <a
                href="#"
                id="compare-table-close"
                className="compare-table-close"
              >
                <span className="compare-table-close-icon" />
              </a>
              <div className="compare-table-items">
                <table id="product-table" className="product-table">
                  <thead>
                    <tr>
                      <th>
                        <a href="#" className="compare-table-settings">
                          Settings
                        </a>
                      </th>
                      <th>
                        <a href="shop-details.html">Twin Hoops</a>{" "}
                        <span className="remove">remove</span>
                      </th>
                      <th>
                        <a href="shop-details.html">Medium Flat Hoops</a>{" "}
                        <span className="remove">remove</span>
                      </th>
                      <th>
                        <a href="shop-details.html">Bold Pearl Hoop Earrings</a>{" "}
                        <span className="remove">remove</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="tr-image">
                      <td className="td-label">Image</td>
                      <td>
                        <a href="shop-details.html">
                          <img
                            width={600}
                            height={600}
                            src="media/product/3.jpg"
                            alt=""
                          />
                        </a>
                      </td>
                      <td>
                        <a href="shop-details.html">
                          <img
                            width={600}
                            height={600}
                            src="media/product/1.jpg"
                            alt=""
                          />
                        </a>
                      </td>
                      <td>
                        <a href="shop-details.html">
                          <img
                            width={600}
                            height={600}
                            src="media/product/2.jpg"
                            alt=""
                          />
                        </a>
                      </td>
                    </tr>
                    <tr className="tr-sku">
                      <td className="td-label">SKU</td>
                      <td>VN00189</td>
                      <td />
                      <td>D1116</td>
                    </tr>
                    <tr className="tr-rating">
                      <td className="td-label">Rating</td>
                      <td>
                        <div className="star-rating">
                          <span style={{ width: "80%" }} />
                        </div>
                      </td>
                      <td>
                        <div className="star-rating">
                          <span style={{ width: "100%" }} />
                        </div>
                      </td>
                      <td />
                    </tr>
                    <tr className="tr-price">
                      <td className="td-label">Price</td>
                      <td>
                        <span className="amount">$150.00</span>
                      </td>
                      <td>
                        <del>
                          <span className="amount">$150.00</span>
                        </del>{" "}
                        <ins>
                          <span className="amount">$100.00</span>
                        </ins>
                      </td>
                      <td>
                        <span className="amount">$200.00</span>
                      </td>
                    </tr>
                    <tr className="tr-add-to-cart">
                      <td className="td-label">Add to cart</td>
                      <td>
                        <div data-title="Add to cart">
                          <a href="#" className="button">
                            Add to cart
                          </a>
                        </div>
                      </td>
                      <td>
                        <div data-title="Add to cart">
                          <a href="#" className="button">
                            Add to cart
                          </a>
                        </div>
                      </td>
                      <td>
                        <div data-title="Add to cart">
                          <a href="#" className="button">
                            Add to cart
                          </a>
                        </div>
                      </td>
                    </tr>
                    <tr className="tr-description">
                      <td className="td-label">Description</td>
                      <td>
                        Phasellus sed volutpat orci. Fusce eget lore mauris
                        vehicula elementum gravida nec dui. Aenean aliquam
                        varius ipsum, non ultricies tellus sodales eu. Donec
                        dignissim viverra nunc, ut aliquet magna posuere eget.
                      </td>
                      <td>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua. Ut enim ad minim veniam, quis nostrud
                        exercitation ullamco laboris nisi ut aliquip ex ea
                        commodo consequat. Duis aute irure dolor in
                        reprehenderit in voluptate velit esse cillum dolore eu
                        fugiat nulla pariatur.
                      </td>
                      <td>
                        The EcoSmart Fleece Hoodie full-zip hooded jacket
                        provides medium weight fleece comfort all year around.
                        Feel better in this sweatshirt because Hanes keeps
                        plastic bottles of landfills by using recycled
                        polyester.7.8 ounce fleece sweatshirt made with up to 5
                        percent polyester created from recycled plastic.
                      </td>
                    </tr>
                    <tr className="tr-content">
                      <td className="td-label">Content</td>
                      <td>
                        Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit, sed do eiusmod tempor incididunt ut labore et
                        dolore magna aliqua. Ut enim ad minim veniam, quis
                        nostrud exercitation ullamco laboris nisi ut aliquip ex
                        ea commodo consequat. Duis aute irure dolor in
                        reprehenderit in voluptate velit esse cillum dolore eu
                        fugiat nulla pariatur. Excepteur sint occaecat cupidatat
                        non proident, sunt in culpa qui officia deserunt mollit
                        anim id est laborum. Sed ut perspiciatis unde omnis iste
                        natus error sit voluptatem accusantium doloremque
                        laudantium, totam rem aperiam, eaque ipsa quae ab illo
                        inventore veritatis et quasi architecto beatae vitae
                        dicta sunt explicabo. Nemo enim ipsam voluptatem quia
                        voluptas sit aspernatur aut odit aut fugit, sed quia
                        consequuntur magni dolores eos qui ratione voluptatem
                        sequi nesciunt. Neque porro quisquam est, qui dolorem
                        ipsum quia dolor sit amet, consectetur, adipisci velit,
                        sed quia non numquam eius modi tempora incidunt ut
                        labore et dolore magnam aliquam quaerat voluptatem.
                      </td>
                      <td>
                        Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit, sed do eiusmod tempor incididunt ut labore et
                        dolore magna aliqua. Ut enim ad minim veniam, quis
                        nostrud exercitation ullamco laboris nisi ut aliquip ex
                        ea commodo consequat. Duis aute irure dolor in
                        reprehenderit in voluptate velit esse cillum dolore eu
                        fugiat nulla pariatur. Excepteur sint occaecat cupidatat
                        non proident, sunt in culpa qui officia deserunt mollit
                        anim id est laborum. Sed ut perspiciatis unde omnis iste
                        natus error sit voluptatem accusantium doloremque
                        laudantium, totam rem aperiam, eaque ipsa quae ab illo
                        inventore veritatis et quasi architecto beatae vitae
                        dicta sunt explicabo. Nemo enim ipsam voluptatem quia
                        voluptas sit aspernatur aut odit aut fugit, sed quia
                        consequuntur magni dolores eos qui ratione voluptatem
                        sequi nesciunt. Neque porro quisquam est, qui dolorem
                        ipsum quia dolor sit amet, consectetur, adipisci velit,
                        sed quia non numquam eius modi tempora incidunt ut
                        labore et dolore magnam aliquam quaerat voluptatem.
                      </td>
                      <td>
                        Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit, sed do eiusmod tempor incididunt ut labore et
                        dolore magna aliqua. Ut enim ad minim veniam, quis
                        nostrud exercitation ullamco laboris nisi ut aliquip ex
                        ea commodo consequat. Duis aute irure dolor in
                        reprehenderit in voluptate velit esse cillum dolore eu
                        fugiat nulla pariatur. Excepteur sint occaecat cupidatat
                        non proident, sunt in culpa qui officia deserunt mollit
                        anim id est laborum. Sed ut perspiciatis unde omnis iste
                        natus error sit voluptatem accusantium doloremque
                        laudantium, totam rem aperiam, eaque ipsa quae ab illo
                        inventore veritatis et quasi architecto beatae vitae
                        dicta sunt explicabo. Nemo enim ipsam voluptatem quia
                        voluptas sit aspernatur aut odit aut fugit, sed quia
                        consequuntur magni dolores eos qui ratione voluptatem
                        sequi nesciunt. Neque porro quisquam est, qui dolorem
                        ipsum quia dolor sit amet, consectetur, adipisci velit,
                        sed quia non numquam eius modi tempora incidunt ut
                        labore et dolore magnam aliquam quaerat voluptatem.
                      </td>
                    </tr>
                    <tr className="tr-dimensions">
                      <td className="td-label">Dimensions</td>
                      <td>N/A</td>
                      <td>N/A</td>
                      <td>N/A</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Quickview */}
      <div className="quickview-popup">
        <div id="quickview-container">
          <div className="quickview-container">
            <a href="#" className="quickview-close" />
            <div className="quickview-notices-wrapper" />
            <div className="product single-product product-type-simple">
              <div className="product-detail">
                <div className="row">
                  <div className="img-quickview">
                    <div className="product-images-slider">
                      <div id="quickview-slick-carousel">
                        <div className="images">
                          <div className="scroll-image">
                            <div className="slick-wrap">
                              <div
                                className="slick-sliders image-additional"
                                data-dots="true"
                                data-columns4={1}
                                data-columns3={1}
                                data-columns2={1}
                                data-columns1={1}
                                data-columns={1}
                                data-nav="true"
                              >
                                <div className="img-thumbnail slick-slide">
                                  <a
                                    href="media/product/3.jpg"
                                    className="image-scroll"
                                    title=""
                                  >
                                    <img
                                      width={900}
                                      height={900}
                                      src="media/product/3.jpg"
                                      alt=""
                                    />
                                  </a>
                                </div>
                                <div className="img-thumbnail slick-slide">
                                  <a
                                    href="media/product/3-2.jpg"
                                    className="image-scroll"
                                    title=""
                                  >
                                    <img
                                      width={900}
                                      height={900}
                                      src="media/product/3-2.jpg"
                                      alt=""
                                    />
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="quickview-single-info">
                    <div className="product-content-detail entry-summary">
                      <h1 className="product-title entry-title">Twin Hoops</h1>
                      <div className="price-single">
                        <div className="price">
                          <del>
                            <span>$150.00</span>
                          </del>
                          <span>$100.00</span>
                        </div>
                      </div>
                      <div className="product-rating">
                        <div
                          className="star-rating"
                          role="img"
                          aria-label="Rated 4.00 out of 5"
                        >
                          <span style={{ width: "80%" }}>
                            Rated <strong className="rating">4.00</strong> out
                            of 5 based on <span className="rating">1</span>{" "}
                            customer rating
                          </span>
                        </div>
                        <a href="#" className="review-link">
                          (<span className="count">1</span> customer review)
                        </a>
                      </div>
                      <div className="description">
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Ut enim ad minim veniam, quis…
                        </p>
                      </div>
                      <form
                        className="cart"
                        method="post"
                        encType="multipart/form-data"
                      >
                        <div className="quantity-button">
                          <div className="quantity">
                            <button type="button" className="plus">
                              +
                            </button>
                            <input
                              type="number"
                              className="input-text qty text"
                              step={1}
                              min={1}
                              max=""
                              name="quantity"
                              defaultValue={1}
                              title="Qty"
                              size={4}
                              placeholder=""
                              inputMode="numeric"
                              autoComplete="off"
                            />
                            <button type="button" className="minus">
                              -
                            </button>
                          </div>
                          <button
                            type="submit"
                            className="single-add-to-cart-button button alt"
                          >
                            Add to cart
                          </button>
                        </div>
                        <button className="button quick-buy">Buy It Now</button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="clearfix" />
          </div>
        </div>
      </div>
      {/* Page Loader */}
      {/* <div className="page-preloader">
        <div className="loader">
          <div />
          <div />
        </div>
      </div> */}
      {/* Dependency Scripts */}
      {/* Site Scripts */}
    </>
  );
};

export default MyAccount;
