import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import toast, { Toaster } from "react-hot-toast";
import Wrapper from "./Wrapper"
import ScreenHeader from "../../components/ScreenHeader";
import Spinner from "../../components/Spinner";
import { useCreateBannerImageMutation, useDeleteBannerImageMutation, useGetAllActiveBannerImagesForUserQuery, useGetAllBannerImagesQuery, useUpdateBannerImageMutation } from "../../store/services/bannerImageService";
import { clearMessage } from "../../store/reducers/globalReducer";
import ImagesPreview from "../../components/ImagesPreview";
import { ImCross } from "react-icons/im";
import { FaCheck } from "react-icons/fa";

const Sliders = () => {
    
    const { data, isFetching } = useGetAllBannerImagesQuery();
    const { success } = useSelector(state => state.global);
    const dispatch = useDispatch();
    const [createBanner, { isLoading: createLoading }] = useCreateBannerImageMutation();
    const [updateBanner, { isLoading: updateLoading }] = useUpdateBannerImageMutation();
    const [state, setState] = useState({
        url: "",
        image: null,
    });

    const [bannerdata, setbannerdata] = useState(data)

    const [delProduct, response] = useDeleteBannerImageMutation();

  
    const deleteBannerHandler = (id) => {
      if (window.confirm("Are you really want to delete this product?")) {
        delProduct(id);
      }
    };
  

    useEffect(() => {
        if (success) {
            toast.success(success);
        }
        return () => {
            dispatch(clearMessage());
        }
    }, [success, dispatch]);

   

    const handleInput = (e) => {
        setState({ ...state, [e.target.name]: e.target.value }); 
    };
    const [preview, setPreview] = useState("");


    const handleImageUpload = (e) => {
        const file = e.target.files[0];
        console.log("Selected file:", file);
        console.log("MIME type:", file.type);
        const extension = file.name.split('.').pop().toLowerCase();
        console.log("File extension:", extension);
        setState({ ...state, image: file });

        const reader = new FileReader();
        reader.onloadend = () => {
            setPreview(reader.result);
        };
        reader.readAsDataURL(e.target.files[0]);
    };

    const createNewBanner = async () => {
        console.log(state,"main state")
        try {
            const response = await createBanner({url:state.url,image:state.image});
            setState({ url: "",
            image: null,})
            setPreview("")
            console.log(response,"main error")
            if (response.error) {
              
                toast.error(response.error.data.error);
            } else {
                toast.success(response.data.msg);
            }
        } catch (error) {
            toast.error('Failed to create banner.');
            console.error('Failed to create banner:', error);
        }
    };

    const handleSubmit = async (bannerId) => {
        try {
            const bannerToUpdate = bannerdata.find(banner => banner._id === bannerId);
            const response = await updateBanner({ id: bannerId, data:bannerToUpdate });
            
            if (response.error) {
                toast.error(response.error.error);
            } else {
                toast.success(response.data.msg);
            }
        } catch (error) {
            toast.error('Failed to update banner.');
            console.error('Failed to update banner:', error);
        }
    };

    useEffect(() => {
    setbannerdata(data)
    }, [data])
    


    const handleUrlChange = (e, id) => {
        const newUrl = e.target.value;
        // Find the specific banner with the given ID
        const updatedBanners = data.map(banner => {
            if (banner._id === id) {
                return { ...banner, url: newUrl };
            }
            return banner;
        });
        // Update the state with the modified banners
        setbannerdata(updatedBanners);
        // Alternatively, dispatch an action to update the URL of the specific banner in the Redux store
        // dispatch(updateBannerUrl({ id, url: newUrl }));
    };

    const activeProduct = (id, status,url) => {
        if (
          window.confirm(
            `Are you really want to ${
              status ? "inactive" : "activate"
            } this product?`
          )
        ) {
            updateBanner({ id: id, data:{active: status ? 0 : 1,url:url} });
        }
      };
    

    return (
        <Wrapper>
            <Toaster position="top-right" />
            <ScreenHeader>
                <h3>Slider</h3>
                <div className="row g-4">
                    <div className="w-full col-4 p-3">
                        <label htmlFor="url" className="label">URL</label>
                        <input
                            type="text"
                            name="url"
                            className="form-control"
                            id="url"
                            placeholder="URL..."
                            onChange={handleInput}
                            value={state.url}
                        />
                    </div>
                    <div className="w-full p-3 col-3">
                        <label htmlFor="image" className="label">Image</label>
                        <input
                            type="file"
                            accept="image/*"
                            name="image"
                            id="image"
                            className="input-file"
                            onChange={handleImageUpload}
                        />
                    </div>
                    <div className="col-12">
                        <button
                            className="w-44 bg-white text-black h-10 font-titleFont text-base tracking-wide font-semibold hover:bg-black hover:text-white duration-200"
                            type="button"
                            disabled={createLoading}
                            onClick={createNewBanner}
                        >
                            Upload
                        </button>
                    </div>
                    <div className="p-3 col-2">
                    <ImagesPreview url={preview} heading="Image Preview" />
                </div>
                </div>
            </ScreenHeader>

            <div  style={{ borderRadius: '5px' }}>
                {!isFetching ? (
                    data?.length > 0 ? (
                        <div>
                            <table className="w-full bg-gray-900 rounded-md">
                                <thead>
                                    <tr className="border-b border-gray-800 text-left">
                                        <th className="p-3 uppercase text-sm font-medium text-gray-500">Image</th>
                                        <th className="p-3 uppercase text-sm font-medium text-gray-500">URL</th>
                                        <th className="p-3 uppercase text-sm font-medium text-gray-500">Edit</th>
                                        <th className="p-3 uppercase text-sm font-medium text-gray-500">Delete</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map(banner => (
                                        <tr className="odd:bg-gray-800" key={banner._id}>
                                       
                                        <td className="p-3">
                                            <img src={`${banner.image}`} alt={banner.url} className="w-20 h-20 rounded-md object-cover" />
                                        </td>
                                        <td className="my-8 capitalize text-sm font-normal text-gray-400 flex flex-row col-8">
                                            <input
                                                type="text"
                                                className="form-control"
                                                defaultValue={banner.url}
                                                onChange={(e) => handleUrlChange(e, banner._id)}
                                            />
                                             <button className="btn btn-warning" onClick={() => handleSubmit(banner._id)}>Update</button>
                                        </td>
                                        <td className="p-3 capitalize text-sm font-normal text-gray-400">
                                        <span
                        className={`btn ${
                            banner.active ? "btn-success" : "btn-danger"
                        } cursor-pointer ml-3`}
                        onClick={() =>
                          activeProduct(banner._id, banner.active,banner.url)
                        }
                      >
                        {banner.active ? <FaCheck/>:<ImCross/>  }
                      </span>
                                        </td>
                                        <td className="p-3 capitalize text-sm font-normal text-gray-400">
                                            <button className="btn btn-danger" onClick={() => deleteBannerHandler(banner._id)}>Delete</button>
                                        </td>
                                    </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    ) : 'No banners!'
                ) : <Spinner />}
            </div>
        </Wrapper>
    );
}

export default Sliders;
