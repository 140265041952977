import { Virtual, Navigation, Autoplay } from "swiper";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { useTrendingProductsQuery } from "../../store/services/homeProducts";
import currency  from "currency-formatter";
const ProductsSwiper = ({data,isFetching}) => {
  

  if (isFetching) {
    return <div>Loading...</div>;
  }

  return (
    <div className="content-product-list slick-wrap">
    <Swiper
      modules={[Virtual, Navigation, Autoplay]}
      spaceBetween={20}
      slidesPerView={4} // Adjust as needed for your design
      navigation={{
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      }}
      autoplay={{
        delay: 3000,
        disableOnInteraction: false,
      }}
      virtual
    //   className="w-full h-[300px] products-swiper"
      breakpoints={{
        0: {
          slidesPerView: 2,
        },
        640: {
          slidesPerView: 3,
        },
        768: {
          slidesPerView: 3,
        },
        1080: {
          slidesPerView: 4,
        },
        1280: {
          slidesPerView: 4,
        },
      }}
    >
      {data?.map((product, index) => (
        <SwiperSlide
          className=" products-list grid w-full overflow-hidden relative"
          key={product.id}
          virtualIndex={index}
        >
        
           <div className="item-product ">
                                <div className="items">
                                  <div className="products-entry clearfix product-wapper">
                                    <div className="products-thumb">
                                      <div className="product-lable">
                                        <div className="hot">Hot</div>
                                      </div>
                                      <div className="product-thumb-hover">
                                        <Link to={`/product/${product._id}`}>
                                        <img
                    width={600}
                    height={600}
                    src={`https://fine1jewels.com/images/${product.images[0]}`}
                    className="post-image"
                    alt={product.name}
                  />
                  <img
                    width={600}
                    height={600}
                    src={`https://fine1jewels.com/images/${product.images[1]}`}
                    className="hover-image back"
                    alt={product.name}
                  />
                                        </Link>
                                      </div>
                                      <div className="product-button">
                                        <div
                                          className="btn-add-to-cart"
                                          data-title="Add to cart"
                                        >
                                          <Link
                                            rel="nofollow"
                                            to="/Cart"
                                            className="product-btn button"
                                          >
                                            Add to cart
                                          </Link>
                                        </div>
                                        <div
                                          className="btn-wishlist"
                                          data-title="Wishlist"
                                        >
                                          <button className="product-btn">
                                            Add to wishlist
                                          </button>
                                        </div>
                                        <div
                                          className="btn-compare"
                                          data-title="Compare"
                                        >
                                          <button className="product-btn">
                                            Compare
                                          </button>
                                        </div>
                                        <span
                                          className="product-quickview"
                                          data-title="Quick View"
                                        >
                                          <a
                                            href="#"
                                            className="quickview quickview-button"
                                          >
                                            Quick View{" "}
                                            <i className="icon-search" />
                                          </a>
                                        </span>
                                      </div>
                                    </div>
                                    <div className="products-content">
              <div className="contents">
                {/* <div className="rating">
                  <div className={`star star-${product.rating}`} />
                  <span className="count">
                    ({product.reviews} review{product.reviews > 1 ? 's' : ''})
                  </span>
                </div> */}
                <h3 className="product-title">
                  <Link to={`/product/${product._id}`}>
                  {product.discount ? (
                    
                      <del>
                        <span>{currency.format(product.pricing.finalPrice +  product.discount, { code: "INR" })}</span>
                      </del>):null}
                  </Link>
                </h3>
                <span className="price">
                  {product.discount ? (
                    <>
                      {/* <del>
                        <span>{currency.format(product.pricing.finalPrice +  product.discount, { code: "INR" })}</span>
                      </del> */}
                      <ins>
                        <span>{currency.format(product.pricing.finalPrice, { code: "INR" })}</span>
                      </ins>
                    </>
                  ) : (
                    <span>
                        {currency.format(product.pricing.finalPrice, { code: "INR" })}
                        </span>
                  )}
                </span>
              </div>
            </div>
                                  </div>
                                </div>
                              </div>
        </SwiperSlide>
      ))}
    </Swiper></div>
  );
};

export default ProductsSwiper;
