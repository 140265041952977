import { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { TwitterPicker } from "react-color";
import { v4 as uuidv4 } from "uuid";
import ReactQuill from "react-quill";
import toast, { Toaster } from "react-hot-toast";
import h2p from "html2plaintext";
import "react-quill/dist/quill.snow.css";
import ScreenHeader from "../../components/ScreenHeader";
import Wrapper from "./Wrapper";
import { useAllCategoriesQuery } from "../../store/services/categoryService";
import {
  useUpdateProductMutation,
  useGetProductQuery,
} from "../../store/services/productService";
import Spinner from "../../components/Spinner";
import Colors from "../../components/Colors";
import SizesList from "../../components/SizesList";
import { setSuccess } from "../../store/reducers/globalReducer";
import ImagesPreview from "../../components/ImagesPreview";
import { useCreateSizeMutation, useGetAllSizesQuery, useRemoveSizeMutation } from "../../store/services/sizeService";
import { AiOutlineDelete } from 'react-icons/ai';

const EditProduct = () => {
  const { id } = useParams();
  const { data: allsizes, error, isLoading } = useGetAllSizesQuery();
  const [createSize] = useCreateSizeMutation();
  const [removeSize] = useRemoveSizeMutation();

  const [sizes, setSizes] = useState([]);
  
  useEffect(() => {
    if (allsizes) {
      setSizes(allsizes.map(size => ({ name: size.name, _id: size._id })));
    }
  }, [allsizes]);

  const { data: product, isFetching: fetching } = useGetProductQuery(id);
  const { data = [], isFetching } = useAllCategoriesQuery();
  const [value, setValue] = useState("");
  const [state, setState] = useState({
    title: "",
    price: 0,
    discount: 0,
    stock: 100,
    category: "",
    colors: [],
    sizes: [],
    images: [],
    shipping: 0,
    shipdetails: '',
        trendingProduct: false, 
  });

  const [preview, setPreview] = useState([]);
  const imageHandle = (e) => {
    if (e.target.files.length !== 0) {
      const newImages = [...state.images];
      const newPreviews = [...preview];
      for (const file of e.target.files) {
        newImages.push(file);
        const reader = new FileReader();
        reader.onloadend = () => {
          newPreviews.push(reader.result);
          setPreview([...newPreviews]);
        };
        reader.readAsDataURL(file);
      }
      setState({ ...state, images: newImages });
    }
  };

  const deleteImage = (index) => {
    const newImages = [...state.images];
    newImages.splice(index, 1);
    const newPreviews = [...preview];
    newPreviews.splice(index, 1);
    setState({ ...state, images: newImages });
    setPreview([...newPreviews]);
  };

  const [customSize, setCustomSize] = useState('');
  const handleInput = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const saveColors = (color) => {
    const filtered = state.colors.filter((clr) => clr.color !== color.hex);
    setState({
      ...state,
      colors: [...filtered, { color: color.hex, id: uuidv4() }],
    });
  };

  const deleteColor = (color) => {
    const filtered = state.colors.filter((clr) => clr.color !== color.color);
    setState({ ...state, colors: filtered });
  };

  const chooseSize = (sizeObject) => {
    const { sizes } = state;
    const filtered = sizes.filter((size) => size.name !== sizeObject.name);
    const newSizeIndex = filtered.findIndex(size => parseInt(size.name) > parseInt(sizeObject.name));
    const newSizes = [...filtered];

    const newSize = {
      ...sizeObject,
      price: sizeObject.price || 0, // Ensure a price property exists
    };

    if (newSizeIndex === -1) {
      newSizes.push(newSize);
    } else {
      newSizes.splice(newSizeIndex, 0, newSize);
    }

    setState({
      ...state,
      sizes: newSizes,
    });
  };

  const deleteSize = (name) => {
    const filtered = state.sizes.filter((size) => size.name !== name);
    setState({ ...state, sizes: filtered });
  };

  const handleCustomSizeChange = (e) => {
    setCustomSize(e.target.value);
  };

  const handleSizePriceChange = (name, price) => {
    const updatedSizes = state.sizes.map(size => {
      if (size.name === name) {
        return { ...size, price };
      }
      return size;
    });
    setState({ ...state, sizes: updatedSizes });
  };

  const addCustomSize = async () => {
    const sizeData = { name: customSize };
    await createSize(sizeData);
    if (customSize && !state.sizes.find(size => size.name === customSize)) {
      const newSizeObject = { name: customSize, price: 0 };
      chooseSize(newSizeObject);
      setCustomSize('');
    } else {
      toast.error('Size already exists or is invalid');
    }
  };

  const [updateProduct, response] = useUpdateProductMutation();

  const createPro = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('_id', id);
    formData.append('title', state.title);
    formData.append('price', state.price);
    formData.append('discount', state.discount);
    formData.append('stock', state.stock);
    formData.append('category', state.category);
    formData.append('shipping', state.shipping);
    formData.append('shipdetails', state.shipdetails);
    formData.append('description', value);
    formData.append('trendingProduct', state.trendingProduct);
  
    state.images.forEach((image, index) => {
      formData.append('images', image);
    });

    formData.append('sizes', JSON.stringify(state.sizes));
  
    updateProduct(formData);
  };

  useEffect(() => {
    if (!response.isSuccess) {
      response?.error?.data?.errors.map((err) => {
        toast.error(err.msg);
      });
    }
  }, [response?.error?.data?.errors]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (response?.isSuccess) {
      dispatch(setSuccess(response?.data?.msg));
      navigate("/dashboard/products");
    }
  }, [response?.isSuccess]);

  useEffect(() => {
    setState({ ...state, description: value });
  }, [value]);

  useEffect(() => {
    if (!fetching) {
      setState(product);
      setPreview(product?.images?.map(image => "/images/" + image) || []);
      setValue(h2p(product?.description));
    }
  }, [product]);

  const handleTrendingChange = () => {
    setState({ ...state, trendingProduct: !state.trendingProduct });
};

  return (
    <Wrapper>
      <ScreenHeader>
        <Link to="/dashboard/products" className="btn-dark">
          <i className="bi bi-arrow-left-short"></i> products list
        </Link>
      </ScreenHeader>
      <Toaster position="top-right" reverseOrder={true} />
      {!fetching ? (
        <div className="flex flex-wrap -mx-3">
          <form className="w-full xl:w-8/12 p-3" onSubmit={createPro}>
            <h3 className="pl-3 capitalize text-lg font-medium text-gray-400">
              edit product
            </h3>
            <div className="flex flex-wrap">
              <div className="w-full md:w-6/12 p-3">
                <label htmlFor="title" className="label">
                  title
                </label>
                <input
                  type="text"
                  name="title"
                  className="form-control"
                  id="title"
                  placeholder="title..."
                  onChange={handleInput}
                  value={state?.title}
                />
              </div>
              <div className="w-full md:w-6/12 p-3">
                <label htmlFor="price" className="label">
                  price
                </label>
                <input
                  type="number"
                  name="price"
                  className="form-control"
                  id="price"
                  placeholder="price..."
                  onChange={handleInput}
                  value={state?.price}
                />
              </div>
              <div className="w-full md:w-6/12 p-3">
                <label htmlFor="discount" className="label">
                  discount
                </label>
                <input
                  type="number"
                  name="discount"
                  className="form-control"
                  id="discount"
                  placeholder="discount..."
                  onChange={handleInput}
                  value={state?.discount}
                />
              </div>
              <div className="w-full md:w-6/12 p-3">
                <label htmlFor="shipping" className="label">Shipping Charges</label>
                <input type="number" name="shipping" className="form-control" id="shipping" placeholder="Shipping Charges..." onChange={handleInput} value={state?.shipping} />
              </div>
              <div className="w-full md:w-6/12 p-3">
                <label htmlFor="stock" className="label">
                  stock
                </label>
                <input
                  type="number"
                  name="stock"
                  className="form-control"
                  id="stock"
                  placeholder="stock..."
                  onChange={handleInput}
                  value={state?.stock}
                />
              </div>
              <div className="w-full md:w-6/12 p-3">
                <label htmlFor="categories" className="label">
                  categories
                </label>
                {!isFetching ? (
                  data?.categories?.length > 0 && (
                    <select
                      name="category"
                      id="categories"
                      className="form-control"
                      onChange={handleInput}
                      value={state?.category}
                    >
                      <option value="">Choose category</option>
                      {data?.categories?.map((category) => (
                        <option value={category.name} key={category._id}>
                          {category.name}
                        </option>
                      ))}
                    </select>
                  )
                ) : (
                  <Spinner />
                )}
              </div>

              <div className="w-full p-3">
                <label htmlFor="sizes" className="label">
                  choose sizes
                </label>
                {sizes.length > 0 && (
                  <div className="flex flex-wrap -mx-3">
                    {sizes.map((size) => (
                      <div key={size._id} className="size flex items-center mb-2">
                        <div onClick={() => chooseSize(size)} className="mr-2">{size.name}</div>
                        <AiOutlineDelete
                          className="cursor-pointer text-red-500"
                          onClick={async () => await removeSize(size._id)}
                        />
                      </div>
                    ))}
                  </div>
                )}
                <div className="flex items-center md:w-6/12 mt-3">
                  <input
                    type="text"
                    value={customSize}
                    onChange={handleCustomSizeChange}
                    placeholder="Add custom size..."
                    className="form-control"
                  />
                  <button
                    type="button"
                    onClick={addCustomSize}
                    className="btn btn-indigo ml-2"
                  >
                    Add
                  </button>
                </div>
              </div>

              <div className="w-full p-3">
                {state.sizes.map((size, index) => (
                  <div key={index} className="flex items-center mb-2">
                    <span className="mr-2">{size.name}</span>
                    <input
                      type="number"
                      value={size.price}
                      onChange={(e) => handleSizePriceChange(size.name, e.target.value)}
                      placeholder="Size Price"
                      className="form-control w-24"
                    />
                    <AiOutlineDelete
                      className="cursor-pointer text-red-500 ml-2"
                      onClick={() => deleteSize(size.name)}
                    />
                  </div>
                ))}
              </div>

              <div className="w-full p-3">
                <label htmlFor="images" className="label">
                  Images
                </label>
                <input type="file" name="images" id="images" className="input-file" onChange={imageHandle} multiple />
              </div>

              <div className="w-full p-3">
                <label htmlFor="description" className="label">
                  Description
                </label>
                <ReactQuill
                  theme="snow"
                  id="description"
                  value={value}
                  onChange={setValue}
                  placeholder="Description..."
                />
              </div>
              <div className="w-full p-3">
                <label htmlFor="shipdetails" className="label">
                  Shipping Instructions
                </label>
                <ReactQuill theme="snow" id="shipdetails" value={state?.shipdetails} onChange={(e) => setState({...state, shipdetails: e})}  placeholder="Shipping Instructions..." />
              </div>
              <div className="w-full p-3">
                            <label className="label">Trending Product</label>
                            <input type="checkbox" id="trendingProduct" checked={state.trendingProduct} onChange={handleTrendingChange} />
                            <label htmlFor="trendingProduct" className="ml-2">Is this product trending?</label>
                        </div>
              <div className="w-full p-3">
                <input
                  type="submit"
                  value={response.isLoading ? "loading..." : "save product"}
                  disabled={response.isLoading ? true : false}
                  className="btn btn-indigo"
                />
              </div>
            </div>
          </form>
          <div className="w-full xl:w-4/12 p-3">
            <SizesList list={state.sizes} deleteSize={deleteSize} />
            {preview.map((url, index) => (
              <div key={index} className="relative">
                <ImagesPreview url={url} heading={`Image ${index + 1}`} />
                <button
                  className="absolute top-2 right-2 bg-red-500 text-white p-1 rounded-full"
                  onClick={() => deleteImage(index)}
                >
                  <AiOutlineDelete />
                </button>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <Spinner />
      )}
    </Wrapper>
  );
};

export default EditProduct;
