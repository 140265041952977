
import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import toast, { Toaster } from "react-hot-toast";
import ScreenHeader from "../../components/ScreenHeader";
import Wrapper from "./Wrapper";
import ImagesPreview from "../../components/ImagesPreview";
import { setSuccess } from "../../store/reducers/globalReducer";
import { useCreateBrandMutation } from "../../store/services/brandService";
import { useCreateInformationMutation, useGetSingleInformationQuery, useUpdateInformationMutation } from "../../store/services/informationService";
import ReactQuill from "react-quill";

const EditPolicyPage = () => {

  const { id } = useParams();
  const { data, isFetching } = useGetSingleInformationQuery(id);
    const [state, setState] = useState({
        title: "",
        details: "",
        url: "",
        status:1
    });

    const [preview, setPreview] = useState("");


    useEffect(() => {
        if(data){
      setState({
        title: data[0].title,
        details: data[0].details,
        url: data[0].url,
        status: data[0].status,
    })}
    }, [data])
    

    const handleInput = (e) => {
        const name = e.target.value;
        const url = generateSlug(name); // Generate slug from name
        setState({ ...state, [e.target.name]: name, url }); 
    };

    const generateSlug = (name) => {
        return name
            .trim()
            .toLowerCase()
            .replace(/\s+/g, "-") // Replace spaces with hyphens
            .replace(/[^\w\-]+/g, "") // Remove non-word characters
            .replace(/\-\-+/g, "-"); // Replace multiple hyphens with single hyphen
    };




    const [createInformation, response] = useUpdateInformationMutation();

    const createBrand = async (e) => {
        e.preventDefault();
        try {
            console.log("State:", state);
            
        
            await createInformation({id:data[0]._id,data:{"title": state.title,"details": state.details,"url": state.url,"status":state.status}}).unwrap();
        } catch (error) {
            console.error("Create brand error:", error);
        }
    };

    useEffect(() => {
        if (!response.isSuccess) {
            response?.error?.data?.errors.map((err) => {
                toast.error(err.msg);
            });
        }
    }, [response]);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (response?.isSuccess) {
            dispatch(setSuccess(response?.data?.msg));
            navigate("/dashboard/policypages");
        }
    }, [response]);

    return (
        <Wrapper>
            <ScreenHeader>
                <Link to="/dashboard/policypages" className="btn-dark">
                    <i className="bi bi-arrow-left-short"></i> Policy Pages
                </Link>
            </ScreenHeader>
            <Toaster position="top-right" reverseOrder={true} />
            <div className="flex flex-wrap -mx-3">
                <form className="w-full xl:w-8/12 p-3" onSubmit={createBrand}>
                    <div className="flex flex-wrap">
                        <div className="w-full md:w-6/12 p-3">
                            <label htmlFor="name" className="label">
                                Name
                            </label>
                            <input
                                type="text"
                                name="title"
                                className="form-control"
                                id="title"
                                placeholder="Title..."
                                onChange={handleInput}
                                value={state.title}
                            />
                        </div>
                        <div className="w-full md:w-6/12 p-3">
                            <label htmlFor="url" className="label">
                                Url
                            </label>
                            <input
                                type="text"
                                name="url"
                                className="form-control"
                                id="url"
                                placeholder="Url..."
                                readOnly
                                value={state.url}
                            />
                        </div>
                       
                        <div className="w-full p-3">
                                <label htmlFor="description" className="label">
                                   Description
                                </label>
                                <ReactQuill theme="snow" id="details" value={state.details}  name="details"  onChange={(value) =>  setState({ ...state, "details": value })}  placeholder="Details..." />
                                </div>
                        <div className="w-full p-3">
                            <input
                                type="submit"
                                value={response.isLoading ? "Loading..." : "Save Brand"}
                                disabled={response.isLoading}
                                className="btn btn-indigo"
                            />
                        </div>
                    </div>
                </form>
               
            </div>
        </Wrapper>
    );
};

export default EditPolicyPage;
