import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.min.css';
import InnerImageZoom from 'react-inner-image-zoom';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';

const ProductImages = ({imageData=[]}) => {

  const [activeImageIndex, setActiveImageIndex] = useState(0);
  const NextArrow = ({  }) => (
    <div className="custom-arrow custom-next-arrow" onClick={() => setActiveImageIndex(activeImageIndex == imageData.length -1 ? 0 : activeImageIndex+1)}>
      <FaArrowRight />
    </div>
  );
  
  const PrevArrow = ({  }) => (
    <div className="custom-arrow custom-prev-arrow" onClick={() => setActiveImageIndex(activeImageIndex == 0 ? imageData.length -1 :activeImageIndex-1)}>
      <FaArrowLeft />
    </div>
  );
  
  const [sliderSettings, setSliderSettings] = useState({
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    vertical: false, // Disable vertical scrolling for the main slider
    verticalSwiping: false,
    centerMode: true,
    focusOnSelect: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  });

  const [thumbnailSliderSettings, setThumbnailSliderSettings] = useState({
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: false,
    vertical: true,
    verticalSwiping: true,
    centerMode: true,
    focusOnSelect: true,
  });

  useEffect(() => {
    // Adjust slider settings based on screen width
    const handleResize = () => {
      const windowWidth = window.innerWidth;
      setThumbnailSliderSettings({
        ...thumbnailSliderSettings,
        vertical: windowWidth >= 768, // Vertical for screens wider than 768px
        verticalSwiping: windowWidth >= 768, // Enable vertical swiping for screens wider than 768px
        slidesToShow: windowWidth >= 1200 ? 4 : windowWidth >= 992 ? 3 : 4, // Show more slides on larger screens
      });
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Initial call to set the correct slider settings

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);



  useEffect(() => {
    // Adjust slider settings based on screen width
    const handleResize = () => {
      const windowWidth = window.innerWidth;
      setSliderSettings({
        ...sliderSettings,
        slidesToShow: windowWidth >= 1200 ? 4 : windowWidth >= 992 ? 3 : windowWidth >= 768 ? 2 : 1,
      });
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  const handleThumbnailClick = (index) => {
    setActiveImageIndex(index);
  };

  return (
    <div className="product-images col-lg-7 col-md-12 col-12">
      <div className="row">
        <div className="col-md-2">
          <div className="content-thumbnail-scroll">
            <Slider {...thumbnailSliderSettings}>
              {imageData?.map((image, index) => (
                <div
                  className={`img-item slick-slide ${index === activeImageIndex ? 'active' : ''}`}
                  key={index}
                  onClick={() => handleThumbnailClick(index)}
                >
                  <span className="img-thumbnail-scroll">
                    <img
                      width={600}
                      height={600}
                      src={`https://fine1jewels.com/images/${image}`}
                      alt=""
                    />
                  </span>
                </div>
              ))}
            </Slider>
          </div>
        </div>
        <div className="col-md-10">
          <div className="scroll-image main-image">
          <NextArrow />
          <InnerImageZoom
              src={`https://fine1jewels.com/images/${imageData[activeImageIndex]}`} // Display the active image
              width={600 * 1.5}
              height={600 * 1.5}
              hasSpacer
              zoomScale={1.5}
            />
            <PrevArrow/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductImages;