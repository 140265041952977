import { Virtual, Navigation, Autoplay } from "swiper";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { useAllCategoriesQuery } from "../../store/services/categoryService";

const Categories = () => {
  const { data, isFetching } = useAllCategoriesQuery();

  if (isFetching) {
    return <div>Loading...</div>;
  }

  return (
    // data?.categories.length > 0 && (
      <Swiper
        modules={[Virtual, Navigation, Autoplay]}
        spaceBetween={20}
        slidesPerView={5} // Adjust as needed for your design
        navigation={{
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        }}
        autoplay={{
          delay: 2000,
          disableOnInteraction: false,
        }}
        virtual
        className="w-full h-[150px] md:h-[250px] categories-swiper"
        breakpoints={{
          0: {
            slidesPerView: 2,
          },
          640: {
            slidesPerView: 3,
          },
          768: {
            slidesPerView: 3,
          },
          1080: {
            slidesPerView: 4,
          },
          1280: {
            slidesPerView: 5,
          },
        }}
      >
        {data?.categories.map((category, index) => {
return(
        
          <SwiperSlide
            className="item item-product-cat w-full overflow-hidden relative"
            key={index}
            virtualIndex={index}
          >
            <div className="item-product-cat-content">
              <Link to={`/shop/${category.name}/${category._id}`}>
                <div className="item-image animation-horizontal">
                  <img
                    width={258}
                    height={258}
                    
                    src={`https://fine1jewels.com/${category?.image}` || "media/product/cat-1.jpg"}
                    alt={category.name}
                    className="object-cover w-full h-full"
                  />
                </div>
              </Link>
              <div className="product-cat-content-info">
                <h2 className="item-title">
                  <Link to={`/shop/${category.name}/${category._id}`}>{category.name}</Link>
                </h2>
              </div>
            </div>
          </SwiperSlide>
        )})}
      </Swiper>
    // )
  );
};

export default Categories;
